import styled from 'styled-components';
import {Heading, TableCell, TableHeader, TableRow} from '@snsw/react-component-library';
import {Label} from '@snsw/react-component-library/build/Accordion/Label';
import {ToggleButton} from '@mui/material';
import {boxShadowStyle, colors, fontSize, globalFont} from '../CommonComponents/Typography/Typography.styled';

export const columnWidths = {
    notification: '262px',
    code: '130px',
    service: '180px',
    agency: '140px',
    channels: '148px',
    updated: '100px',
    status: '122px',
    actionButton: '35px'
};

export const TH = styled(TableHeader)`
  //styleName: Para - Semi;
  font-family: Public Sans, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: left;
  color: #002664;
  padding-top: unset;
  padding-bottom: unset;
`;

export const TR = styled(TableRow)`
  height: 41px;
`;

export const DashboardHeading = styled(Heading)`
  //styleName: H1;
  font-family: Public Sans, serif;
  font-size: 28px;
  font-weight: 700;
  color: #002664;
  line-height: 36.4px;
  text-align: left;
  margin: unset;
  padding-bottom: 8px;

`;

export const TextCell = styled(TableCell)`
  //styleName: Para - Regular;
  font-family: Public Sans, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: left;
  color: #646974;
  padding-top: unset;
  padding-bottom: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
`;

export const Cell = styled(TableCell)`
  //styleName: Para - Regular;
  font-family: Public Sans, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: left;
  color: #646974;
  padding-top: unset;
  padding-bottom: unset;
  background-color: white;
  table-layout: fixed;
  overflow: hidden;
`;

export const LiveStatusLabel = styled(Label)`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 40px;
  height: 24px;
  left: 20px;
  top: 20px;

  /* Notification colours/SNSW Success Green Light #E5F6E6 */
  background: #E5F6E6;
  border-radius: 3px;
  color: #00A908;
  padding-left: 25px;
  padding-right: 25px;
  margin: 6px;
`;

export const InReviewStatusLabel = styled(Label)`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 80px;
  height: 24px;
  left: 20px;
  top: 92px;

  /* Notification colours/SNSW Warning Orange Light #FCEFE6 */
  background: #FCEFE6;
  border-radius: 3px;
  color: #DC5800;
  margin: 6px;
`;
export const SentStatusLabel = styled(Label)`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 40px;
  height: 24px;
  left: 20px;
  top: 20px;

  background: #DEE3E5;
  border-radius: 3px;
  color: #242934;
  padding-left: 25px;
  padding-right: 25px;
  margin: 6px;
`;
export const ArchivedStatusLabel = styled(Label)`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 58px;
  height: 24px;
  left: 20px;
  top: 92px;

  background: #DEE3E5;
  border-radius: 3px;
  color: #646974;
  margin: 6px;
`;
export const UnknownStatusLabel = styled(Label)`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 58px;
  height: 24px;
  left: 20px;
  top: 92px;

  background: #e71936;
  border-radius: 3px;
  color: #edeef1;
  margin: 6px;
`;

export const FilterButton = styled(ToggleButton)`
  /* Button */
  box-sizing: border-box;

  /* Auto layout */
  gap: 8px;
  padding: 14px 16px;
  width: 115px;
  height: 40px;

  /* Grey colours/SNSW White #FFFFFF */
  background: #FFFFFF !important;
  border-width: 1.5px !important;
  border-style: solid;
  border-color: ${colors.grey.geyser};

  &:focus {
    border-color: ${colors.grey.neutral200};
    box-shadow: ${boxShadowStyle};
  }

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;

  /* Label */
  font-family: ${globalFont};
  font-weight: 500;
  font-size: ${fontSize.body} !important;
  text-align: center;
  text-transform: unset !important;
`;
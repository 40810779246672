import React from 'react';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {ComponentWrapper, ContentWrapper, ImgWrapper, Img, Heading, Body} from './InquireComponent.styled';

const InquireComponent = () => {
    const navigateToDigitalMarketplace =() => {
        window.open('https://www.service.nsw.gov.au/about-us/digital-capabilities/digital-notifications#get-in-touch', '_blank');
    };
    return (
        <ComponentWrapper id='formSection'>
            <ContentWrapper>
                <div><Heading>Want to work with us?</Heading></div>
                <Body>
                    {/* eslint-disable-next-line max-len */}
                    <p>Head over to Digital Marketplace, and we’ll respond within 5 business days. We’ll only accept inquiries from NSW Government accounts.</p>
                </Body>
                <div>
                    <Button
                        onClick={ navigateToDigitalMarketplace }
                        variant='primary'
                    >Inquire</Button>
                </div>
            </ContentWrapper>
            <ImgWrapper>
                <Img src={ `${process.env.PUBLIC_URL}/assets/images/inquire.png` } alt='inquire'></Img>
            </ImgWrapper>
        </ComponentWrapper>
    );
};

export default InquireComponent;

import React, {useEffect, useState} from 'react';
import {GlobalNav, setGlobalNavUri} from '@snsw/react-global-nav';
import {Col, InPageAlert, Row, SectionPage} from '@snsw/react-component-library';
import CustomHeader from './CustomHeader/CustomHeader';
import BenefitsComponent from './BenefitsComponent/BenefitsComponent';
import TestimonialComponent from './TestimonialComponent/TestimonialComponent';
import {BodyContainer, ComponentWrapper, CustomerContentContainer} from './HomePageComponent.styled';
import WorkFlowComponent from './WorkFlowComponent/WorkFlowComponent';
import IntroductionComponent from './IntroductionComponent/IntroductionComponent';
import InquireComponent from './InquireComponent/InquireComponent';
import PageBodyWrapper from './PageBodyWrapper';
import ProductsAndServiceComponent from './ProductsAndServiceComponent/ProductsAndServiceComponent';
import RequestAccessComponent from './RequestAccessComponent/RequestAccessComponent';

setGlobalNavUri(
    `${process.env.REACT_APP_GLOBALNAV_DOMAIN}`,
    { 'x-api-key': `${process.env.REACT_APP_GLOBALNAV_APIKEY}` },
);
const HomePageComponent = () => {
    const [alert, setAlert] = useState( {
        variant: '',
        title: '',
        description: '',
        visible: false
    });
    useEffect(() => {
        if (alert.visible) {
            const timer = setTimeout(() => {
                setAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, 60000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [alert]);

    return (
        <>
            <CustomHeader setAlert={ setAlert }/>
            <GlobalNav style={ {marginTop: '112px', backgroundColor: 'rgb(255, 255, 255)'} } showHeader={ false }
                footer={ 'compressed' }>
                {(alert.visible) &&
                    <ComponentWrapper style={ {backgroundColor: 'rgb(255, 255, 255)', paddingTop: '15px'} }>
                        <CustomerContentContainer>
                            <SectionPage>
                                <Row justifyContent={ 'center' }>
                                    <Col span={ 12 }>
                                        <InPageAlert id='login-status' variant={ alert.variant } title={ alert.title }>
                                            <p id='loginStatusDesc'>{alert.description}</p>
                                        </InPageAlert>
                                    </Col>
                                </Row>
                            </SectionPage>
                        </CustomerContentContainer>
                    </ComponentWrapper>}
                <BodyContainer>
                    <PageBodyWrapper component={ IntroductionComponent }/>
                    <PageBodyWrapper component={ BenefitsComponent }/>
                    <PageBodyWrapper component={ ProductsAndServiceComponent }/>
                    <PageBodyWrapper component={ TestimonialComponent } />
                    <PageBodyWrapper component={ WorkFlowComponent } />
                    <PageBodyWrapper component={ InquireComponent } />
                    <PageBodyWrapper component={ RequestAccessComponent } />
                </BodyContainer>
                {/*<RequestComponent/>*/}
            </GlobalNav>
        </>
    );
};

export default HomePageComponent;

import axios from 'axios';
import {Buffer} from 'buffer';
import DOMPurify from 'dompurify';

// We use DOMPurify in multiple places in the code. Please only set the DOMPurify config here for visibility.
DOMPurify.setConfig({
    // eslint-disable-next-line max-len
    ALLOWED_URI_REGEXP: /(\{\{)?((https|snsw-mobile-app):\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=-]{2,256}(\.[a-z]{2,6}\b)?([-a-zA-Z0-9@:%_+.~#?&/=]*)(\}\})?/
});
// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
        node.setAttribute('rel', 'noopener noreferrer');
    }
    // set non-HTML/MathML links to xlink:show=new
    if (!node.hasAttribute('target')
        && (node.hasAttribute('xlink:href')
            || node.hasAttribute('href'))) {
        node.setAttribute('xlink:show', 'new');
    }
});

const config = {
    headers: {
        'Access-Control-Allow-Origin': `${process.env.REACT_APP_HOME_URL}`,
        'Content-Type': 'application/json'
    }
};

const searchNotificationCode = async (notificationCode, inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/notifications/${notificationCode}`;
    return axios.get(url, {...config, params: {inProd: inProd}, retries: 2}).then(res => {
        return res.data;
    });
};

const searchServiceCode = async (serviceCode, inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/services/${serviceCode}`;
    return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        return res.data;
    });
};

const searchAgencyCode = async (agencyCode, inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/agencies/${agencyCode}`;
    return await axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        return res.data;
    });
};

const fetchUserRole = async (inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/userinfo';
    return axios.get(url, config).then(res => {
        return res.data;
    });
};

const fetchAgencies = async (inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/agencies';
    return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        return res.data;
    });
};

const fetchServices = async (inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/services';
    return axios.get(url, {...config, params: {inProd: inProd}, retries: 2}).then(res => {
        return res.data;
    });
};

const fetchNotifications = async (serviceCode, inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/services/${serviceCode}`;
    return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        return res.data.notifications;
    });
};

const fetchTemplates = async (inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/contents';
    return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        const data = {
            templates: {
                headers: [],
                footers: [],
                feedbacks: [],
                inboxFooters: []
            },
            mainContent: ''
        };
        res.data.forEach(template => {
            if (template.type === 'HEADER') {
                data.templates.headers.push({value: template.id.toString(), text: template.description});
            } else if (template.type === 'FOOTER') {
                data.templates.footers.push({value: template.id.toString(), text: template.description});
            } else if (template.type === 'INBOX_FOOTER') {
                data.templates.inboxFooters.push({value: template.id.toString(), text: template.description});
            } else if (template.type === 'FEEDBACK') {
                data.templates.feedbacks.push({value: template.id.toString(), text: template.description});
            } else if (template.description === 'MainContent') {
                data.mainContent = template.id.toString();
            }
        });
        return data;
    });
};

const fetchTemplateContentOptionsByTypeAndDescription = async (inProd, type, description) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/contents';
    const templateContentData = [];
    return axios.get(url, {
        ...config,
        params: {inProd: inProd, type: type, description: description},
        retries: 2
    }).then(res => {
        res.data.forEach(
            templateContent => {
                templateContentData.push({
                    value: templateContent.id.toString(), text: templateContent.description,
                    content: templateContent.content
                });
            });
        return templateContentData;
    });
};

const fetchTemplateContentOptionsByTypes = async (inProd, types) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/contents?type=' + types;
    return axios.get(url, {...config, params: {inProd: inProd}, retries: 2}).then(res => {
        const data = {
            templates: {
                headers: [],
                footers: [],
                feedbacks: [],
                inboxFooters: []
            }
        };
        res.data.forEach(template => {
            if (template.type === 'HEADER') {
                data.templates.headers.push({
                    value: template.id.toString(),
                    text: template.description,
                    content: template.content
                });
            } else if (template.type === 'FOOTER') {
                data.templates.footers.push({
                    value: template.id.toString(),
                    text: template.description,
                    content: template.content
                });
            } else if (template.type === 'FEEDBACK') {
                data.templates.feedbacks.push({
                    value: template.id.toString(),
                    text: template.description,
                    content: template.content
                });
            } else if (template.type === 'INBOX_FOOTER') {
                data.templates.inboxFooters.push({
                    value: template.id.toString(),
                    text: template.description,
                    content: template.content
                });
            }
        });
        return data;
    });
};

// Returns base64 encoded string that contains html content
const fetchContentsByContentId = async (inProd, id) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/contents/${id}`;
    return axios.get(url, {...config, params: {inProd: inProd}})
        .then(res => {
            return res.data.content;
        })
        .catch(error => {
            console.log(`caught error: ${error}`);
        });
};

const postCampaignNotification = async (CampaignNotificationRequest, environment) => {

    const formData = new FormData();
    // console.log(CampaignNotificationRequest.file);
    formData.append('file', CampaignNotificationRequest.file);
    formData.append('request', new Blob([JSON.stringify({
        'notificationCode': CampaignNotificationRequest.notificationCode
    })], {
        type: 'application/json'
    }));
    formData.append('sentBy', CampaignNotificationRequest.sentBy);

    const url = process.env.REACT_APP_PORTAL_BFF + '/campaign';
    return axios.post(url, formData, {
        headers: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_HOME_URL}`,
            'Content-Type': 'multipart/form-data'
        }, params: {destinationEnv: environment}
    });
};

const postBatchNotification = async (notificationRequest, environment) => {
    const batchNotificationRequest = {
        notificationCode: notificationRequest.notificationCode,
        customerIds: notificationRequest.customerIds,
        emails: notificationRequest.emails,
        mobileNumbers: notificationRequest.mobileNumbers,
        notificationContent: {
            ...((notificationRequest.email.content.length > 0 && notificationRequest.email.subject.length > 0) ? {
                email: {
                    subject: notificationRequest.email.subject,
                    content: notificationRequest.email.content
                }
            } : {}),
            ...((notificationRequest.web.content.length > 0) ? {
                web: {
                    title: notificationRequest.web.title,
                    content: notificationRequest.web.content
                }
            } : {}),
            ...((notificationRequest.sms.content.length > 0) ? {
                sms: {
                    content: notificationRequest.sms.content
                }
            } : {}),
            ...((notificationRequest.push.title.length > 0) ? {
                push: {
                    content: notificationRequest.push.content,
                    title: notificationRequest.push.title
                }
            } : {}),
            ...((notificationRequest.notificationDetails.data.length > 0) ? {
                notificationDetails: notificationRequest.notificationDetails
            } : {})
        }
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/notification/batch';
    return axios.post(url, batchNotificationRequest, {...config, params: {destinationEnv: environment}});
};

const postSingleNotification = async (notificationRequest, environment) => {
    const singleNotificationRequest = {
        notificationCode: notificationRequest.notificationCode,
        ...(notificationRequest.customerId.length > 0 ? {customerId: notificationRequest.customerId} : {}),
        notificationType: 'TEST_PORTAL_NOTIFICATION',
        ...((notificationRequest.email.content.length > 0 && notificationRequest.email.subject.length > 0) ? {
            email: {
                ...(notificationRequest.email.to.length > 0 ? {to: notificationRequest.email.to} : {}),
                subject: notificationRequest.email.subject,
                content: notificationRequest.email.content
            }
        } : {}),
        ...((notificationRequest.web.content.length > 0) ? {
            web: {
                title: notificationRequest.web.title,
                content: notificationRequest.web.content
            }
        } : {}),
        ...((notificationRequest.sms.content.length > 0) ? {
            sms: {
                ...(notificationRequest.sms.to.length > 0 ? {to: notificationRequest.sms.to} : {}),
                content: notificationRequest.sms.content
            }
        } : {}),
        ...((notificationRequest.push.content.length > 0) ? {
            push: {
                content: notificationRequest.push.content,
                title: notificationRequest.push.title
            }
        } : {}),
        ...((notificationRequest.notificationDetails.data.length > 0) ? {
            notificationDetails: notificationRequest.notificationDetails
        } : {})
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/notification';
    return axios.post(url, singleNotificationRequest, {...config, params: {destinationEnv: environment}});

};

const postSingleNotificationV2CustomerId = async (notificationRequest, environment) => {
    const singleNotificationRequest = {
        notificationCode: notificationRequest.notificationCode,
        customerId: notificationRequest.customerId
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/notification/v2';
    return axios.post(url, singleNotificationRequest, {...config, params: {destinationEnv: environment}});
};

const postNotificationConfig = async (newNotificationRequest) => {
    const body = {
        service: newNotificationRequest.service,
        notificationCode: newNotificationRequest.notificationCode,
        description: newNotificationRequest.description,
        notificationCategory: 'PERSONAL',
        feedback: newNotificationRequest.feedback,
        showInInbox: newNotificationRequest.showInInbox,
        sendPushEnabled: newNotificationRequest.sendPushEnabled,
        sendEmailEnabled: newNotificationRequest.sendEmailEnabled,
        sendSmsEnabled: newNotificationRequest.sendSmsEnabled,
        emailSourceApplicationTUTD: newNotificationRequest.emailSourceApplicationTUTD,
        inboxSourceApplicationTUTD: newNotificationRequest.inboxSourceApplicationTUTD,
        notificationType: newNotificationRequest.notificationType.toUpperCase()
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/notifications';
    return axios.post(url, body, config);
};

const postAgencyConfig = async (newAgencyConfig) => {
    const body = {
        agencyCode: newAgencyConfig.agencyCode,
        description: newAgencyConfig.description,
        dataExchangeAgencyCode: newAgencyConfig.dataExchangeAgencyCode,
        services: []
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/agencies';
    return axios.post(url, body, config);
};

const updateAgencyConfig = async (agencyConfig) => {
    const body = {
        agencyCode: agencyConfig.agencyCode,
        description: agencyConfig.description,
        dataExchangeAgencyCode: agencyConfig.dataExchangeAgencyCode,
        services: []
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/agencies';
    return axios.put(url, body, config);
};

const postServiceConfig = async (newServiceConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/services';
    return axios.post(url, newServiceConfig, config);
};

const updateServiceConfig = async (serviceConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/services';
    return axios.put(url, serviceConfig, config);
};

const getContentsByNotificationId = (notificationId, notificationChannel, inProd) => {
    const contentsByNotificationUrl = process.env.REACT_APP_PORTAL_BFF +
        `/config/contents/notification/${notificationId}`;
    return axios.get(contentsByNotificationUrl, {
        ...config,
        params: {inProd: inProd, notificationChannel: notificationChannel}
    }).then(response => {
        return response.data;
    });
};

const getTemplateWithContentsByNotificationId = (notificationId, notificationChannel, inProd) => {
    const contentsByNotificationUrl = process.env.REACT_APP_PORTAL_BFF +
        `/config/template/notification/${notificationId}`;
    return axios.get(contentsByNotificationUrl, {
        ...config,
        params: {inProd: inProd, notificationChannel: notificationChannel},
        retries: 2
    }).then(response => {
        return response.data;
    });
};

const compareAgency = (agencyId) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/pending/agencies/${agencyId}`;
    return axios.get(url, config).then(res => {
        return res.data;
    });
};

const compareService = (serviceCode, propagatePendingChangeStatusToParent = true, destinationEnv = 'DST') => {
    const url = process.env.REACT_APP_PORTAL_BFF +
        `/config/pending/services/${serviceCode}?destinationEnv=${destinationEnv}`;
    return axios.get(url, {
        ...config,
        params: {propagatePendingChangeStatusToParent: propagatePendingChangeStatusToParent}
    }).then(res => {
        return res.data;
    });
};

const compareNotification = (notificationCode, propagatePendingChangeStatusToParent = true, destinationEnv = 'DST') => {
    const url = process.env.REACT_APP_PORTAL_BFF +
        `/config/pending/notifications/${notificationCode}?destinationEnv=${destinationEnv}`;
    return axios.get(url, {
        ...config,
        params: {propagatePendingChangeStatusToParent: propagatePendingChangeStatusToParent},
        retries: 2
    }).then(res => {
        return res.data;
    });
};

const removeNotification = async notificationCode => {
    /**
     * Uncomment below commented code to integrate the backend API call for deletion
     * Remove the line 'return Promise.resolve();'
     */
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/notifications/${notificationCode}`;
    return axios.delete(url, config);
};

const removeService = async serviceCode => {
    /**
     * Uncomment below code to integrate the backend API call for deletion
     * Remove the line 'return Promise.resolve();'
     */
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/services/${serviceCode}`;
    return axios.delete(url, config);
};

const updateNotificationConfig = (notificationConfig) => {
    const notificationObject = {
        id: notificationConfig.id,
        service: notificationConfig.service.id,
        notificationCode: notificationConfig.notificationCode,
        description: notificationConfig.description,
        showInInbox: notificationConfig.showInInbox,
        sendPushEnabled: notificationConfig.sendPushEnabled,
        sendEmailEnabled: notificationConfig.sendEmailEnabled,
        sendSmsEnabled: notificationConfig.sendSmsEnabled,
        feedback: notificationConfig.feedback,
        ...(notificationConfig.ocr !== null && {ocr: notificationConfig.ocr}),
        notificationCategory: notificationConfig.notificationCategory,
        inboxSourceApplicationTUTD: notificationConfig.inboxSourceApplicationTUTD,
        emailSourceApplicationTUTD: notificationConfig.emailSourceApplicationTUTD,
        status: notificationConfig.status,
        notificationType: notificationConfig.notificationType.toUpperCase()
    };

    const url = process.env.REACT_APP_PORTAL_BFF + '/config/notifications';
    return axios.put(url, notificationObject, config);
};

const archiveNotification = async (notificationConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/notifications/${notificationConfig.notificationCode}/archive`;
    return axios.patch(url, config);
};

function getEmailFeedbackId(emailFeedbackTemplate) {
    const feedbackId = emailFeedbackTemplate !== null ? Number(emailFeedbackTemplate) || null : null;
    return feedbackId !== null ? {feedbackId} : {};
}

const updateEmailTemplateConfigs = (notificationCode, templateConfig) => {
    const emailTemplateConfig = {
        headerId: Number(templateConfig.emailHeaderTemplate),
        footerId: Number(templateConfig.emailFooterTemplate),
        content: Buffer.from(templateConfig.emailContent).toString('base64'),
        subject: Buffer.from(templateConfig.emailSubject).toString('base64'),
        ...getEmailFeedbackId(templateConfig.emailFeedbackTemplate)
    };
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/email/templates/${notificationCode}`;
    return axios.put(url, emailTemplateConfig, config);
};

const createEmailTemplateConfigs = (notificationCode, notificationConfig) => {
    const emailTemplateConfig = {
        headerId: Number(notificationConfig.emailHeaderTemplate),
        footerId: Number(notificationConfig.emailFooterTemplate),
        content: Buffer.from(notificationConfig.emailContent).toString('base64'),
        subject: Buffer.from(notificationConfig.emailSubject).toString('base64'),
        ...getEmailFeedbackId(notificationConfig.emailFeedbackTemplate)
    };
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/email/templates/${notificationCode}`;
    return axios.post(url, emailTemplateConfig, config);
};

const updateInboxTemplateConfigs = (notificationCode, templateConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/inbox/templates/${notificationCode}`;
    const inboxTemplateConfig = {
        // content: Buffer.from(sanitizer(templateConfig.inboxContent)).toString('base64'),
        subject: Buffer.from(templateConfig.inboxSubject).toString('base64'),
        content: Buffer.from(templateConfig.inboxContent).toString('base64'),
        footerId: Number(templateConfig.inboxFooterTemplate)
    };
    return axios.put(url, inboxTemplateConfig, config);
};

const createInboxTemplateConfigs = (notificationConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/inbox/templates/${notificationConfig.notificationCode}`;
    const inboxTemplateConfig = {
        subject: Buffer.from(notificationConfig.inboxSubject).toString('base64'),
        // content: Buffer.from(sanitizer(notificationConfig.inboxContent)).toString('base64'),
        content: Buffer.from(notificationConfig.inboxContent).toString('base64'),
        footerId: Number(notificationConfig.inboxFooterTemplate)
    };
    return axios.post(url, inboxTemplateConfig, config);
};

const createPushTemplateConfigs = (notificationConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/push/templates/${notificationConfig.notificationCode}`;
    const pushTemplateConfig = {
        subject: Buffer.from(notificationConfig.pushSubject).toString('base64'),
        content: Buffer.from(notificationConfig.pushContent).toString('base64')
    };
    return axios.post(url, pushTemplateConfig, config);
};

const updatePushTemplateConfigs = (notificationCode, templateConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/push/templates/${notificationCode}`;
    const pushTemplateConfig = {
        subject: Buffer.from(templateConfig.pushSubject).toString('base64'),
        content: Buffer.from(templateConfig.pushContent).toString('base64')
    };
    return axios.put(url, pushTemplateConfig, config);
};

const createSmsTemplateConfigs = (notificationConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/sms/templates/${notificationConfig.notificationCode}`;
    const smsTemplateConfig = {
        content: Buffer.from(notificationConfig.smsContent).toString('base64')
    };
    return axios.post(url, smsTemplateConfig, config);
};

const updateSmsTemplateConfigs = (notificationCode, templateConfig) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/sms/templates/${notificationCode}`;
    const smsTemplateConfig = {
        content: Buffer.from(templateConfig.smsContent).toString('base64')
    };
    return axios.put(url, smsTemplateConfig, config);
};

const promoteAgencyToDestination = async (agencyCode, destinationEnv = 'DST') => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/promote/agency/${agencyCode}?destinationEnv=${destinationEnv}`;
    return axios.post(url, config);
};

const promoteServiceToDestination = async (serviceCode, destinationEnv = 'DST') => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/promote/service/${serviceCode}?destinationEnv=${destinationEnv}`;
    return axios.post(url, config);
};

const promoteNotificationToDestination = async (notificationCode, destinationEnv = 'DST') => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/promote/notification/${notificationCode}?destinationEnv=${destinationEnv}`;
    return axios.post(url, config);
};

const getNotificationsServiceMap = async (agencyCode = null, environment = 'SRC') => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/notifications-service-map';
    return axios.get(url, {
        ...config,
        params: {
            environment: environment,
            ...(agencyCode ? {agencyCode: agencyCode} : {})
        }
    }).then(res => {
        return res.data;
    });
};

const getServiceCodeIdMap = async (inProd = false) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/preference/config/serviceCode-id-map';
    return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        return res.data;
    });
};

const getPreferenceConfig = async (serviceCode, inProd = false) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/preference/config/${serviceCode}`;
    return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
        return res.data;
    });
};

function getEncodedPreferenceConfig(preferenceConfig) {
    return {
        ...preferenceConfig,
        preferenceAlerts: preferenceConfig.preferenceAlerts.map(alert => {
            const {preferenceConfigId, ...prefAlert} = alert;
            if (alert.description) {
                return {
                    ...prefAlert,
                    actionLinkText: alert.actionLinkText !== null ? alert.actionLinkText : '',
                    actionLinkHref: alert.actionLinkHref !== null ? alert.actionLinkHref : '',
                    description: Buffer.from(alert.description, 'utf8').toString('base64')
                };
            } else {
                return {
                    ...prefAlert,
                    actionLinkText: alert.actionLinkText !== null ? alert.actionLinkText : '',
                    actionLinkHref: alert.actionLinkHref !== null ? alert.actionLinkHref : '',
                    description: ''
                };
            }
        })
    };
}

const createPreferenceConfig = async (serviceCode, preferenceConfig) => {
    const encodedPreferenceConfig = getEncodedPreferenceConfig(preferenceConfig);
    const url = process.env.REACT_APP_PORTAL_BFF + `/preference/config/${serviceCode}`;
    return axios.post(url, encodedPreferenceConfig, config);
};

const updatePreferenceConfig = async (serviceCode, preferenceConfig) => {
    const encodedPreferenceConfig = getEncodedPreferenceConfig(preferenceConfig);
    const url = process.env.REACT_APP_PORTAL_BFF + `/preference/config/${serviceCode}`;
    return axios.put(url, encodedPreferenceConfig, config);
};

const getPendingPreferenceConfig = async (serviceCode, destinationEnv = 'DST') => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/preference/config/pending/${serviceCode}?destinationEnv=${destinationEnv}`;
    return axios.get(url, config).then(res => {
        return res.data;
    });
};

const getAllPreferencePageServices = async (inProd = false) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/services';
    return axios.get(url, {...config, params: {inProd: inProd, search: 'showInPreferencePage:true'}})
        .then(res => {
            return res.data;
        });
};

const promotePreferenceConfig = async (serviceCode, preferenceConfig, destinationEnv = 'DST') => {
    const preferenceConfigToPromote =
        {
            ...preferenceConfig,
            preferenceAlerts: preferenceConfig.preferenceAlerts.map(alert => {
                const {actionLinkHref, actionLinkText, preferenceConfigId, ...prefAlert} = alert;
                return {...prefAlert};
            })
        };
    const url = process.env.REACT_APP_PORTAL_BFF + `/preference/config/promote/${serviceCode}?destinationEnv=${destinationEnv}`;
    return axios.post(url, preferenceConfigToPromote, config).then(res => {
        return res.data;
    });
};

const getLoginInfo = async () => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/user/login';
    return axios.get(url).then(res => {
        return res.data;
    });
};
const getLogoutInfo = async () => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/user/logout';
    return axios.get(url).then(res => {
        return res.data;
    });
};
const getActiveOutage = async () => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/outage?active=true';
    return axios.get(url).then(res => {
        return res.data;
    });
};

const createUser = async (newUser) => {
    const body = {
        email: newUser.email,
        userRoles: newUser.userRole,
        fullName: newUser.fullName,
        teamName: newUser.teamName,
        userTeams: newUser.userTeams
    };
    console.log('body = ', body);
    const url = process.env.REACT_APP_PORTAL_BFF + '/users';
    return axios.post(url, body, config);
};
const updateUser = async (userConfig) => {
    const body = {
        id: userConfig.id,
        email: userConfig.email,
        userRoles: userConfig.userRoles,
        fullName: userConfig.fullName,
        teamName: userConfig.teamName,
        userTeams: userConfig.userTeams
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/users';
    return axios.put(url, body, config);
};

const fetchUserList = async () => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/users';
    return axios.get(url).then(res => {
        return res.data;
    });
};
const fetchUserRoleList = async () => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/users/roles';
    return axios.get(url).then(res => {
        return res.data;
    });
};
const fetchUserById = async (userId) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/users/getUserById/${userId}`;
    return axios.get(url).then(res => {
        return res.data;
    });
};

const fetchUserByEmail = async (email) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/users/getUserByEmail/${email}`;
    return axios.get(url).then(res => {
        return res.data;
    });
};

const deleteUserById = async (userId) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/users/deleteUser/${userId}`;
    return axios.delete(url, config);
};

const syncNotificationToNonProd = async (notificationCode) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/config/sync/notification/${notificationCode}`;
    return axios.post(url, config);
};
const getAllNotificationsConfigs = async (inProd) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/config/notifications';
    try {
        return axios.get(url, {...config, params: {inProd: inProd}}).then(res => {
            return res.data;
        });
    } catch (error) {
        console.log(error.response.data);
    }
};

const getTeamByName = async (teamName) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/teams/filter?name=${teamName}`;
    return axios.get(url, {
        ...config,
        validateStatus: function (status) {
            // Resolve also for status 404 because it is expected in some cases
            return (status >= 200 && status < 300) || status === 404;
        }
    });
};

const getTeamById = async (teamId) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/teams/filter?id=${teamId}`;
    return axios.get(url, config);
};

const createTeam = async (teamConfig) => {
    const body = {
        name: teamConfig.name,
        description: teamConfig.description,
        authorisedServices: teamConfig.authorisedServices
    };
    const url = process.env.REACT_APP_PORTAL_BFF + '/teams';
    return axios.post(url, body, config);
};

const updateTeam = async (teamConfig) => {
    const body = teamConfig;
    const url = process.env.REACT_APP_PORTAL_BFF + '/teams';
    return axios.put(url, body, config);
};

const deleteTeam = async (teamId) => {
    const url = process.env.REACT_APP_PORTAL_BFF + `/teams/${teamId}`;
    return axios.delete(url, config);
};

const fetchTeams = async () => {
    const url = `${process.env.REACT_APP_PORTAL_BFF}/teams`;
    return axios.get(url).then(res => {
        return res.data;
    });
};
const fetchLgaList = async () => {
    console.log('fetching all LGAs');
    return axios.get(`${process.env.REACT_APP_PORTAL_BFF}/segment/lga/metadata`).then(res => {
        const lgaList = [];

        res.data.forEach((lga) => {
            lgaList.push(lga.lgaName);
        });

        console.log(JSON.stringify(lgaList));
        return lgaList;
    });
};

const apiServices = {
    searchNotificationCode,
    postCampaignNotification,
    postBatchNotification,
    postSingleNotification,
    postSingleNotificationV2CustomerId,
    fetchUserRole,
    fetchAgencies,
    fetchServices,
    fetchTemplates,
    fetchTemplateContentOptionsByTypes,
    fetchTemplateContentOptionsByTypeAndDescription,
    fetchContentsByContentId,
    postNotificationConfig,
    postServiceConfig,
    updateServiceConfig,
    postAgencyConfig,
    searchAgencyCode,
    fetchNotifications,
    updateAgencyConfig,
    searchServiceCode,
    compare: compareAgency,
    compareService,
    compareNotification,
    getContentsByNotificationId,
    getTemplateWithContentsByNotificationId,
    updateNotificationConfig,
    updateEmailTemplateConfigs,
    createEmailTemplateConfigs,
    updateInboxTemplateConfigs,
    createInboxTemplateConfigs,
    updatePushTemplateConfigs,
    createPushTemplateConfigs,
    updateSmsTemplateConfigs,
    createSmsTemplateConfigs,
    removeNotification,
    removeService,
    promoteAgencyToDestination,
    promoteServiceToDestination,
    promoteNotificationToDestination,
    getNotificationsServiceMap,
    getServiceCodeIdMap,
    getPreferenceConfig,
    getAllPreferencePageServices,
    createPreferenceConfig,
    updatePreferenceConfig,
    getPendingPreferenceConfig,
    promotePreferenceConfig,
    getLoginInfo,
    getLogoutInfo,
    getActiveOutage,
    fetchUserList,
    createUser,
    updateUser,
    fetchUserRoleList,
    fetchUserById,
    fetchUserByEmail,
    deleteUserById,
    syncNotificationToNonProd,
    getAllNotificationsConfigs,
    getTeamByName,
    getTeamById,
    createTeam,
    updateTeam,
    deleteTeam,
    fetchTeams,
    archiveNotification,
    fetchLgaList
};

export default apiServices;

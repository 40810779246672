import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import apiServices from '../../services/api-services';
import {alertTimeoutValues} from '../../constants/applicationConstants';

import {useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {FormGroup, Modal} from '@snsw/react-component-library';
import {
    AudienceContainer,
    CampaignComponentHeader,
    CampaignDescription
} from './SendCampaignComponent.styled';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {
    PortalFormContainer, AutosuggestGlobalStyle, PortalH5Heading
} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import RadioButtonGroup from '../CommonComponents/RadioButtonGroup/RadioButtonGroup';
import {AutoSuggest} from '@snsw/react-component-library/build/Components';
import FileUploadPattern from '../CommonComponents/FileUpload/FileUploadPattern';
import {Heading1} from '../CommonComponents/Typography/Typography.styled';
import ConfirmNotificationDetailsComponent from './ConfirmNotificationDetailsComponent';
import SegmentsComponent from './SegmentsComponent';

export const sourceOptions = [{
    value: 'select-segment',
    label: 'Service NSW MyAccount customers',
    clarify: 'Select a segment of customers'
}, {
    value: 'upload-list',
    label: 'Upload your list of customers',
    clarify: 'TXT format of Customer IDs'
}];

const SendCampaignComponent = () => {
    const {userRole} = useUserRoleState();
    const navigate = useNavigate();

    const [CampaignNotificationRequest, setCampaignNotificationRequest] = useState({
        notificationCode: '',
        serviceCode: '',
        sentBy: userRole.email,
        file: null,
        conditions: []
    });
    const [fetchNotificationsMap, setFetchNotificationsMap] = useState(true);
    const [notificationConfig, setNotificationConfig] = useState({});
    const [notificationsServiceMap, setNotificationsServiceMap] = useState({});
    const [inputErrors, setInputErrors] = useState({
        notificationCode: {hasError: false, errorMessage: 'Invalid Notification Code'},
    });
    const [errorMessage, setErrorMessage] = useState('');
    const {state} = useLocation();
    const [alert, setAlert] = useState(state && state.visible ? {
        variant: state.variant,
        title: state.title,
        description: state.description,
        visible: state.visible
    } : {
        variant: '',
        title: '',
        description: '',
        visible: false
    });
    const [showSendModal, openSend, closeSend] = useBoolean(false);
    const [showSuccessModal, openSuccess, closeSuccess] = useBoolean(false);
    const [showErrorModal, openError, closeError] = useBoolean(false);
    const [errorCode, setErrorCode] = useState('');

    const defaultOption = sourceOptions[1].value;
    const [selectedValue, setSelectedValue] = useState(defaultOption);
    const [showFileUpload, setShowFileUpload] = useState(defaultOption === sourceOptions[1].value);

    const [refContainerWidth, setRefContainerWidth] = useState(0);
    const audienceContainerRef = useRef(null);

    const updateSelectedValue = (value) => {
        setSelectedValue(value);
        if (value === sourceOptions[1].value) {
            setShowFileUpload(true);
        } else {
            setShowFileUpload(false);
        }
    };

    const onSelect = (suggestion, value) => {
        setCampaignNotificationRequest(prevState => ({
            ...prevState,
            notificationCode: value
        }));
        if (inputErrors.notificationCode.hasError) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: false, errorMessage: 'You must provide a Notification Code'}
            }));
        }
        if (value) {
            apiServices.searchNotificationCode(value, true)
                .then(response => {
                    setNotificationConfig(response);
                    setCampaignNotificationRequest(prevState => ({
                        ...prevState,
                        serviceCode: response.service.serviceCode
                    }));
                })
                .catch((e) => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        notificationCode: {hasError: true, errorMessage: 'Notification code not found'}
                    }));
                });
        }
    };

    useEffect(() => {
        if (alert.visible) {
            const timer = setTimeout(() => {
                setAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, alertTimeoutValues[alert.variant]);
            return () => {
                clearTimeout(timer);
            };
        }
        if (audienceContainerRef.current) {
            setRefContainerWidth(audienceContainerRef.current.offsetWidth);
        }
    }, [alert, userRole]);

    const handleInputChange = (suggestion, value) => {
        if (fetchNotificationsMap) {
            apiServices.getNotificationsServiceMap(null, 'DST').then(map => {
                setNotificationsServiceMap(map);
                setFetchNotificationsMap(false);
            })
                .catch(error => console.log(error.message));
        }
        if (inputErrors.notificationCode.hasError) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: false, errorMessage: 'Invalid Notification Code'}
            }));
        }
    };

    const validateRequest = () => {
        let valid = true;
        const {
            notificationCode
        } = CampaignNotificationRequest;
        if (notificationCode.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'You must provide a Notification Code'}
            }));
            valid = false;
        }
        return valid;
    };

    const sendCampaign = async () => {
        if (validateRequest()) {
            apiServices.postCampaignNotification(CampaignNotificationRequest, 'DST')
                .then((response) => {
                    closeSend();
                    if (response.status === 200) {
                        openSuccess();
                    }
                })
                .catch((e) => {
                    closeSend();
                    setErrorCode(e.response.status);
                    openError();
                });
        }
    };

    return (
        <PortalFormContainer>
            <CampaignComponentHeader
                data-test='send-campaign-header'
                id='sendCampaignHeader'>
                <Heading1>Send a notification</Heading1>
            </CampaignComponentHeader>
            <CampaignDescription style={ {marginBottom: 14} }>
                Send a campaign notification to a select group of customers at once.
            </CampaignDescription>

            <FormGroup
                id='notification-code-input'
                label='Select notification code'
                hasError={ inputErrors.notificationCode.hasError }
                errorMessage={ inputErrors.notificationCode.errorMessage }
                margin={ {top: 24} }
            >
                <AutosuggestGlobalStyle width={ refContainerWidth }/>
                <AutoSuggest
                    data-test='notification-code-input'
                    id='notification-search-id'
                    name='notificationCode'
                    suggestions={ Object.keys(notificationsServiceMap) }
                    onSelect={ onSelect }
                    onChange={ handleInputChange }
                    onBlur={ onSelect }
                    placeholder={ 'Search' }
                    margin={ {top: 'lg'} }
                    value={ CampaignNotificationRequest.notificationCode }
                />
            </FormGroup>

            <AudienceContainer ref={ audienceContainerRef }>
                <PortalH5Heading level={ 5 } style={ {marginBottom: '0.5rem', marginTop: '1.25rem'} }>
                    Audience</PortalH5Heading>
                <RadioButtonGroup
                    label='Select source'
                    options={ sourceOptions }
                    defaultOption={ sourceOptions[1].value }
                    selectedValue={ selectedValue }
                    onChange={ updateSelectedValue }
                />
                <SegmentsComponent
                    refContainerWidth={ refContainerWidth }
                    setCampaignNotificationRequest={ setCampaignNotificationRequest }
                    showAddSegment={ !showFileUpload }
                >
                </SegmentsComponent>

                <FileUploadPattern id='upload-customer-list' label='Upload Customer ID list:' margin={ {top: '16'} }
                    showFileUpload={ showFileUpload }
                    setRequest={ setCampaignNotificationRequest }
                    errorMessage={ errorMessage }
                    setErrorMessage={ setErrorMessage }
                    legendText='' maxMBSize={ 10 } maxFiles={ 1 } acceptedFormats='.txt'/>

            </AudienceContainer>

            <FormButtonContainer style={ {
                display: 'flex', flexWrap: 'nowrap', justifyContent: 'start',
                flexDirection: 'column', marginTop: 24
            } }>
                <div style={ {width: '100%', display: 'flex', flexDirection: 'row', columnGap: '25px'} }>
                    <Button
                        onClick={ openSend }
                        variant='primary'
                        disabled={ !CampaignNotificationRequest.notificationCode || !CampaignNotificationRequest.file }
                        id='createNotificationBtn'>
                        Send notification
                    </Button>
                </div>
            </FormButtonContainer>
            {showSendModal &&
                <Modal
                    title='Ready to send?'
                    description={ 'You\'re about to send a group notification to:' }
                    buttons={ [
                        {text: 'Send Notification', onClick: () => sendCampaign()},
                        {text: 'Cancel', onClick: () => closeSend()}
                    ] }>
                    <ConfirmNotificationDetailsComponent notificationDescription={ notificationConfig.description }
                        notificationCode={ notificationConfig.notificationCode }
                        service={ notificationConfig.service }/>
                </Modal>}
            {showSuccessModal &&
                <Modal
                    title='Sending!'
                    description='Your notification is on its way. It may take up to 2 hours to send.'
                    buttons={ [
                        {
                            text: 'Go to dashboard', onClick: () => {
                                closeSuccess();
                                navigate('/dashboard');
                            }
                        }
                    ] }>
                </Modal>}
            {showErrorModal &&
                <Modal
                    title='Error with send'
                    description='Your notification did not send. Try again or contact Notifications support.'
                    buttons={ [
                        {
                            text: 'Go back', onClick: () => {
                                closeError();
                            }
                        }
                    ] }>
                    Error code: {errorCode}.
                </Modal>}
        </PortalFormContainer>

    );
};
export default SendCampaignComponent;

import React, {useEffect, useRef, useState} from 'react';
import {ComponentLoader, FormSelect, HorizontalRule, Modal} from '@snsw/react-component-library';
import FormCheckboxGroup from '@snsw/react-component-library/build/Components/Form/FormCheckboxGroup';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Form from '@snsw/react-component-library/build/Components/Form/Form';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import {FormButtonContainer, FormLegend} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import apiServices from '../../services/api-services';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {useLocation, useParams} from 'react-router-dom';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import {scrollToTop, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import PreviewContentModal from '../PreviewContentModal/PreviewContentModal';
import {
    IconEmail,
    IconExternalLink,
    IconFeedback,
    IconMobile,
    IconNotifications
} from '@snsw/react-component-library/build/Icons/system';
import {sortBy} from 'lodash';
import TextLink from '@snsw/react-component-library/build/Components/TextLink/TextLink';
import {notificationChannel} from '../../constants/applicationConstants';
import EmailContentFormComponent from '../EmailContentFormComponent/EmailContentFormComponent';
import InboxContentFormComponent from '../InboxContentFormComponent/InboxContentFormComponent';
import SmsContentFormComponent from '../SmsContentFormComponent/SmsContentFormComponent';
import PushContentFormComponent from '../PushContentFormComponent/PushContentFormComponent';
import {
    CustomContentContainer,
    CustomContentContainerForTabHeadings,
    FormFooterContainer,
    StyledTabPanel,
    TabHeading,
    TabHeadingsContainer,
    TabPanelContainer,
    TabsContainer
} from './CreateNotificationComponent.styled';
import {StringParam, useQueryParam} from 'use-query-params';
import RadioButtonGroup from '../CommonComponents/RadioButtonGroup/RadioButtonGroup';

//region Text Copy
export const notificationDescriptionFormField = {
    label: 'Name your notification',
    name: 'description',
    helpMessage: 'Add a short description, not a subject line.',
    placeholder: 'e.g. Product / transaction + type + month + year'
};
export const notificationCodeFormField = {
    label: 'Create a code',
    name: 'notificationCode',
    helpMessage: 'Use uppercase letters and underscores only, so we can find it in our database.',
    placeholder: 'e.g. CK_VOU_REM_DEC'
};

export const serviceSelectFormField = {
    label: 'Select service',
    placeholder: 'Select service',
    errorMessage: 'Please select a service'
};

export const notificationChannelFormField = {
    name: 'notificationChannels',
    legend: 'Select your channels:',
    helpMessage: 'This is where the customer(s) will receive the notification. The medium or method of delivery.',
    inbox: {
        label: 'MyServiceNSW Inbox',
        clarify: 'A message sent to your customer’s Service NSW account inbox.'
    },
    push: {
        label: 'Push',
        clarify: 'A clickable pop-up message that appears on a customer’s mobile device, sent from the Service NSW app.'
    },
    email: {
        label: 'Email',
        clarify: 'An electronic message sent to your customer’s email address.'
    },
    sms: {
        label: 'SMS',
        clarify: 'A text message sent to your customer’s mobile phone number.'
    }
};

export const inboxFormField = {
    heading: 'Content',
    subject: {
        label: 'Inbox subject line',
        helpMessage: 'Ideally 28-50 characters.',
        name: 'inboxSubject',
        placeholder: 'e.g. Your transaction has been approved'
    },
    content: {
        legend: 'Inbox body content',
        helpMessage: 'Ideally 25-125 characters.',
        label: 'Inbox Content',
        name: 'inboxContent',
        placeholder: 'Enter content in html format'
    },
    footer: {
        label: 'Select a footer',
        name: 'inboxFooterTemplate',
        placeholder: 'Select a footer',
        helpMessage: 'Select the consent type that most accurately applies to your audience\'s given consent'
    }
};

export const emailFormField = {
    heading: 'Content',
    subject: {
        label: 'Email subject line',
        helpMessage: 'Ideally 28-50 characters.',
        name: 'emailSubject',
        placeholder: 'e.g. Your transaction has been approved'
    },
    header: {
        label: 'Email header',
        helpMessage: 'Use the combined logo otherwise contact us.',
        name: 'emailHeaderTemplate',
        placeholder: 'Select a header'
    },
    content: {
        legend: 'Email body',
        helpMessage: 'Ideally 25-125 Characters.',
        label: 'Email Content',
        name: 'emailContent',
        placeholder: 'Enter content in html format'
    },
    footer: {
        label: 'Email footer',
        helpMessage: 'Informs your customer of why they received this notification.',
        name: 'emailFooterTemplate',
        placeholder: 'Select an email footer'
    },
    feedback: {
        label: 'Customer feedback widget',
        name: 'emailFeedbackTemplate',
        placeholder: 'Select a feedback widget',
        helpMessage: 'Do not use.'
    },
    emailSourceApplication: {
        label: 'Email Source Application',
        name: 'emailSourceApplicationTUTD'
    }
};

export const pushFormField = {
    heading: 'Content',
    subject: {
        label: 'Push title',
        helpMessage: 'Ideally 20 to 30 characters. Cannot exceed 65 characters.',
        name: 'pushSubject',
        placeholder: 'e.g. Your transaction has been approved'
    },
    content: {
        legend: 'Push body',
        helpMessage: 'Ideally 40 to 50 characters. Cannot exceed 240 characters.',
        label: 'Push Content',
        name: 'pushContent'
    }
};

export const smsFormField = {
    heading: 'Content',
    content: {
        legend: 'SMS body',
        helpMessage: 'Ideally 50 to 160 characters. Should not exceed 320 characters.',
        label: 'SMS Content',
        name: 'smsContent'
    }
};

export const statusNextMsg = {
    title: 'What happens next?',
    reviewMsg: 'We\'ll test and review your notification, then notify you when it\'s ready to send.',
    notifyMsg: (
        <>
            For help, go to our&nbsp;
            <TextLink
                target={ '_blank' }
                href={ `${process.env.REACT_APP_SERVICE_NSW_WIKI_URL}/1032061067/DN+Platform+Features` }>
                FAQs
            </TextLink>
            &nbsp;or&nbsp;
            <TextLink href={ 'mailto:notifications@service.nsw.gov.au' }>contact us.</TextLink>
        </>
    )
};

export const contentGuidanceHelpMsg = 'Review our content guidelines to ensure your notification aligns with ' +
    'Service NSW brand guidelines and best practices.';
//endregion

export const ContentHeadingAndGuidance = () => {
    return <><HorizontalRule style={ {
        margin: '2rem 0 0 0'
    } }/>
    <Heading level={ 3 } style={ {margin: '1.5rem 0 0 0'} }>Notification content</Heading>
    <FormLegend style={ {marginTop: '0.75rem'} }>{contentGuidanceHelpMsg}</FormLegend>
    <Button
        data-testid='contentGuidanceButton'
        variant='secondary'
        style={ {textDecoration: 'none', margin: '1.5rem 0 3.5rem 0'} }
        target='_blank'
        href={ `${process.env.REACT_APP_SERVICE_NSW_WIKI_URL}/11923522847/Content+design+DN` }
    >Go to content guidance <IconExternalLink color='secondaryBlue'/>
    </Button>
    </>;
};

export const StatusNextInfoMsg = () => {
    return <div>
        <Heading level={ 3 } style={ {
            margin: '1rem 0 1rem 0'
        } }>{statusNextMsg.title}</Heading>
        <p style={ {margin: 0, padding: 0} }>{statusNextMsg.reviewMsg}</p>
        <div style={ {height: '15px'} }></div>
        <p style={ {margin: 0, padding: 0} }>{statusNextMsg.notifyMsg}</p>
    </div>;
};
export const encodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML.replace(/<br>/g, '\n');
};

export const redirectToRequestAccessPage = (navigate) => {
    navigate('/AccessRequest');
};

export const validateEmailSourceApplicationTUTD = (emailSourceApplicationTUTD,
    sendEmailEnabled,
    emailFeedbackTemplate,
    templateOptions,
    setInputErrors) => {
    const pattern = /^[a-zA-Z0-9-_]+$/;
    let valid = true;
    if (sendEmailEnabled && emailSourceApplicationTUTD.trim().length === 0 && emailFeedbackTemplate ===
        templateOptions.feedbacks.find(feedback => feedback.text === 'Feedback-TUTD').value) {
        setInputErrors(prevState => ({
            ...prevState,
            emailSourceApplicationTUTD: {
                hasError: true, errorMessage: 'You must provide a source application for TUTD Feedback'
            }
        }));
        valid = false;
    } else if (emailSourceApplicationTUTD.trim().length !== 0) {
        valid = pattern.test(emailSourceApplicationTUTD);
        if (!valid) {
            setInputErrors(prevState => ({
                ...prevState,
                emailSourceApplicationTUTD: {hasError: !valid, errorMessage: 'Invalid Source Application'}
            }));
        }

    }
    return valid;
};
//validateTextField is a generalised version of validateNotificationEmailSubject & validateNotificationDescription.
export const validateTextField = (input, inputFieldType, setInputErrors) => {
    let pattern;
    //Used to find non-allowed characters for display in UI
    let patternInverted;
    let error;
    let valid;
    if (inputFieldType === 'description') {
        error = 'a notification name';
        pattern = /^[a-zA-Z0-9-_&()/.,’\s]+$/;
        patternInverted = /[^a-zA-Z0-9-_&()/.,’\s]/g;
    } else {
        if (inputFieldType === 'inboxSubject') {
            error = 'an inbox subject line';
        } else if (inputFieldType === 'emailSubject') {
            error = 'an email subject line';
        } else if (inputFieldType === 'pushSubject') {
            error = 'a push title';
        } else if (inputFieldType === 'pushContent') {
            error = 'push body content';
        } else if (inputFieldType === 'smsContent') {
            error = 'SMS body content';
        }
        pattern = /^[a-zA-Z0-9-_&(){}[\]:|/\\.,’!@#$%*=+<>?;'"\s]+$/;
        patternInverted = /[^a-zA-Z0-9-_&(){}[\]:|/\\.,’!@#$%*=+<>?;'"\s]/g;
    }
    if (input.length === 0) {
        setInputErrors(prevState => ({
            ...prevState,
            [inputFieldType]: {hasError: true, errorMessage: 'Enter ' + error}
        }));
        valid = false;
    } else {
        valid = pattern.test(input);
        const invalidChars = [...new Set(input.match(patternInverted))];
        const err = valid ? '' : `Do not use: ${invalidChars.join(' , ')}`;
        setInputErrors(prevState => ({
            ...prevState,
            [inputFieldType]: {hasError: !valid, errorMessage: err}
        }));
    }
    return valid;
};
export const notificationTypes = [
    {
        value: 'campaign',
        label: 'Campaign',
        clarify: 'Manually sent, once-off broadcast',
        icon: 'campaign'
    }, {
        value: 'automated',
        label: 'Automated',
        clarify: 'Ongoing, triggered by API or data',
        icon: 'automated'
    }
];

export const sortOptionsByText = (a, b) => {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
    }
    if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
    }
    return 0;
};

const CreateNotificationComponent = () => {
    const {serviceCode} = useParams();
    const {state} = useLocation();
    const {userRole} = useUserRoleState();
    const [agencyCodeFromPath, setAgencyCodeFromPath] = useState(null);
    const [agencyName, setAgencyName] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [selectedAgencyCode, setSelectedAgencyCode] = useState('');
    const [selectedServiceCode, setSelectedServiceCode] = useState('');
    const navigate = useNavigate();
    const [showModal, open, close] = useBoolean(false);
    const inboxContentValidation = useRef(false);
    const emailContentValidation = useRef(false);
    const [showInboxPreviewModal, setShowInboxPreviewModal] = useState(false);
    const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
    const [showPushPreviewModal, setShowPushPreviewModal] = useState(false);
    const [showSmsPreviewModal, setShowSmsPreviewModal] = useState(false);
    const [inputErrors, setInputErrors] = useState({
        description: {hasError: false, errorMessage: 'Enter a notification name'},
        notificationType: {hasError: false, errorMessage: 'Select a notification type'},
        notificationCode: {hasError: false, errorMessage: 'Invalid Notification Code'},
        service: {hasError: false, errorMessage: 'Please select a service'},
        notificationChannels: {hasError: false, errorMessage: 'You must select at least one channel'},
        emailSubject: {hasError: false, errorMessage: 'Provide an email subject line'},
        emailContent: {hasError: false, errorMessage: 'Enter email body content in html format'},
        emailFooterTemplate: {hasError: false, errorMessage: 'Select an email footer'},
        emailHeaderTemplate: {hasError: false, errorMessage: 'Select an email header'},
        inboxFooterTemplate: {hasError: false, errorMessage: 'Select an inbox footer'},
        inboxSubject: {hasError: false, errorMessage: 'Please provide an inbox subject'},
        inboxContent: {hasError: false, errorMessage: 'Enter inbox body content in html format'},
        pushSubject: {hasError: false, errorMessage: 'Enter a push title'},
        pushContent: {hasError: false, errorMessage: 'Enter push body content'},
        smsContent: {hasError: false, errorMessage: 'Enter SMS body content'},
        emailSourceApplicationTUTD: {
            hasError: false,
            errorMessage: 'You must provide a source application for TUTD Feedback'
        },
        inboxSourceApplicationTUTD: {
            hasError: false,
            errorMessage: 'You must provide a source application for TUTD Feedback'
        }
    });
    const [alert, setAlert] = useState({
        variant: 'success',
        title: 'Notification created and submitted', // eslint-disable-next-line max-len
        description: 'Your notification has been successfully created and is now being reviewed by the notifications team. ' +
            'We will be in contact via email with next steps regarding your notification.',
        visible: false
    });
    const [newNotificationRequest, setNewNotificationRequest] = useState({
        service: '',
        description: '',
        notificationCode: '',
        showInInbox: false,
        sendSmsEnabled: false,
        sendEmailEnabled: false,
        sendPushEnabled: false,
        emailSubject: '',
        emailHeaderTemplate: '',
        emailContent: '',
        emailFooterTemplate: '',
        emailFeedbackTemplate: '',
        emailBodyTemplate: '',
        inboxFooterTemplate: '',
        inboxSubject: '',
        inboxContent: '',
        pushSubject: '',
        pushContent: '',
        smsContent: '',
        feedback: true,
        emailSourceApplicationTUTD: '',
        inboxSourceApplicationTUTD: ''
    });
    // Always follow below Array object structure as checkbox options prop
    const defaultCheckboxOptions = [
        {
            isChecked: false, label: 'Email', value: 'sendEmailEnabled',
            clarify: notificationChannelFormField.email.clarify
        },
        {
            isChecked: false, label: 'Push', value: 'sendPushEnabled',
            clarify: notificationChannelFormField.push.clarify
        },
        {
            isChecked: false, label: 'MyServiceNSW Inbox', value: 'showInInbox',
            clarify: notificationChannelFormField.inbox.clarify
        },
        {
            isChecked: false, label: 'SMS', value: 'sendSmsEnabled',
            clarify: notificationChannelFormField.sms.clarify
        }

    ];
    const [checkboxChannels, setCheckboxChannels] = useState(defaultCheckboxOptions);
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [templateOptions, setTemplateOptions] = useState({
        headers: [{value: '', text: '', content: ''}],
        footers: [{value: '', text: '', content: ''}],
        feedbacks: [{value: '', text: '', content: ''}],
        inboxFooters: [{value: '', text: '', content: ''}]
    });
    const [tabIndex, setTabIndex] = useState(0);
    const [serviceOptions, setServiceOptions] = useState([{value: '', text: ''}]);
    const [from] = useQueryParam('from', StringParam);

    const [notificationType, setNotificationType] = useState('');

    useEffect(() => {
        scrollToTop();
        if (from !== 'dashboard') {
            apiServices.searchServiceCode(serviceCode).then((data) => {
                setAgencyCodeFromPath(data.agency.agencyCode);
            })
                .catch(error => console.log(error.message));
        }
        apiServices.fetchTemplateContentOptionsByTypes(inProd, ['HEADER', 'FOOTER', 'FEEDBACK', 'INBOX_FOOTER']).then(res => {
            const templates = {
                headers: sortBy(res.templates.headers, 'text'),
                footers: sortBy(res.templates.footers, 'text'),
                feedbacks: sortBy(res.templates.feedbacks, 'text'),
                inboxFooters: sortBy(res.templates.inboxFooters, 'text')
            };
            setTemplateOptions(templates);
            setNewNotificationRequest(prevState => ({
                ...prevState,
                emailHeaderTemplate: res.templates.headers[0].value
            }));
        })
            .catch(error => console.log(error.message));
        apiServices.fetchTemplateContentOptionsByTypeAndDescription(inProd, 'CONTENT', 'MainContent').then(data => {
            if (data.length > 0) {
                // Assuming that there is only one content record with unique description 'MainContent'
                setNewNotificationRequest(prevState => ({
                    ...prevState,
                    emailBodyTemplate: data[0].value
                }));
            }
        })
            .catch(error => console.log(error.message));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        const options = [];
        apiServices.fetchServices().then(data => {
            data.forEach(service => {
                options.push({
                    text: service.description + ' (' + service.serviceCode + ')',
                    value: service.id,
                    serviceCode: service.serviceCode,
                    serviceName: service.description,
                    agencyName: service?.agency?.description,
                    agencyCode: service.agency?.agencyCode
                });
            });
            options.sort((a, b) => sortOptionsByText(a, b));
            setServiceOptions(options);
        })
            .catch(error => console.log(error.message));
    }, [userRole]);

    const handleServiceSelect = (event) => {
        const selectedService = serviceOptions.find(service => service.value === Number(event.target.value));
        if (event.target.value && selectedService) {
            setSelectedServiceCode(event.target.value);
            setNewNotificationRequest(prevState => ({
                ...prevState,
                service: selectedService?.value
            }));
            setServiceName(selectedService?.serviceName);
            setAgencyName(selectedService?.agencyName);
            setSelectedAgencyCode(selectedService?.agencyCode);
            setInputErrors(prevState => ({
                ...prevState,
                service: {hasError: false, errorMessage: serviceSelectFormField.errorMessage}
            }));
        } else {
            setInputErrors(prevState => ({
                ...prevState,
                service: {hasError: true, errorMessage: serviceSelectFormField.errorMessage}
            }));
        }
    };

    const handleChange = event => {
        setAlert(prevState => ({
            ...prevState,
            visible: false
        }));
        const {name, value} = event.target;
        setNewNotificationRequest(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'}
        }));
        if (name === 'inboxContent' && value.length > 0 && inboxContentValidation.current) {
            inboxContentValidation.current = false;
        }
        if (name === 'emailContent' && value.length > 0 && emailContentValidation.current) {
            emailContentValidation.current = false;
        }
    };

    const onChannelChangeEvent = e => {
        setAlert(prevState => ({
            ...prevState,
            visible: false
        }));
        setInputErrors(prevState => ({
            ...prevState,
            notificationChannels: {hasError: false, errorMessage: 'You must select at least one channel'}
        }));
        const val = e.target.value;
        const checkedChannels = [...checkboxChannels];
        checkedChannels.forEach(item => {
            if (item.value === val) {
                item.isChecked = !item.isChecked;
                setNewNotificationRequest(prevState => ({
                    ...prevState,
                    [val]: item.isChecked
                }));
                // If PUSH is enabled, then Inbox should be enabled as well.
                // Inbox is index 2 in the Checkbox channel array.
                if (item.value === 'sendPushEnabled' && item.isChecked && !checkedChannels[2].isChecked) {
                    checkedChannels[2].isChecked = true;
                    setNewNotificationRequest(prevState => ({
                        ...prevState,
                        showInInbox: true
                    }));
                }
                // If Inbox and PUSH are enabled, when Inbox is disabled, PUSH should be disabled as well.
                // PUSH is index 1 in the Checkbox channel array.
                if (item.value === 'showInInbox' && checkedChannels[1].isChecked) {
                    checkedChannels[1].isChecked = false;
                    setNewNotificationRequest(prevState => ({
                        ...prevState,
                        sendPushEnabled: false
                    }));
                }
                // If currently selected tab corresponds to deselected channel, switch to tab 0
                if (tabIndex >= checkedChannels.filter(channel => channel.isChecked).length) {
                    setTabIndex(0);
                }
            }
        });
        setCheckboxChannels(checkedChannels);
        if (val === 'sendEmailEnabled') {
            setNewNotificationRequest(prevState => ({
                ...prevState,
                emailSubject: '',
                emailHeaderTemplate: templateOptions.headers[0].value,
                emailContent: '',
                emailFooterTemplate: '',
                emailFeedbackTemplate: '',
                emailSourceApplicationTUTD: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                emailSubject: {hasError: false, errorMessage: 'Provide an email subject line'},
                emailFooterTemplate: {hasError: false, errorMessage: 'Select an email footer'},
                emailContent: {hasError: false, errorMessage: 'Enter email body content in html format'},
                emailHeaderTemplate: {hasError: false, errorMessage: 'Select an email header'},
                emailSourceApplicationTUTD: {
                    hasError: false, errorMessage:
                        'You must provide a source application for TUTD Feedback'
                }
            }));
        } else if (val === 'showInInbox') {
            setNewNotificationRequest(prevState => ({
                ...prevState,
                inboxFooterTemplate: '',
                inboxSubject: '',
                inboxContent: '',
                feedback: false,
                inboxSourceApplicationTUTD: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                inboxFooterTemplate: {hasError: false, errorMessage: 'Select an inbox footer'},
                inboxSubject: {hasError: false, errorMessage: 'Enter inbox subject line'},
                inboxContent: {hasError: false, errorMessage: 'Enter inbox body content in html format'},
                inboxSourceApplicationTUTD: {
                    hasError: false,
                    errorMessage: 'You must provide a source application for TUTD Feedback'
                }
            }));
        } else if (val === 'sendPushEnabled') {
            setNewNotificationRequest(prevState => ({
                ...prevState,
                pushSubject: '',
                pushContent: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                pushSubject: {hasError: false, errorMessage: 'Enter push title'},
                pushContent: {hasError: false, errorMessage: 'Enter push body content'}
            }));
        } else if (val === 'sendSmsEnabled') {
            setNewNotificationRequest(prevState => ({
                ...prevState,
                smsContent: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                smsContent: {hasError: false, errorMessage: 'Enter SMS body content'}
            }));
        }
    };

    const emailSourceApplicationTUTDChangeHandle = async () => {
        return validateEmailSourceApplicationTUTD(newNotificationRequest.emailSourceApplicationTUTD,
            newNotificationRequest.sendEmailEnabled,
            newNotificationRequest.emailFeedbackTemplate,
            templateOptions,
            setInputErrors);
    };
    // notificationTextChangeHandle is a generalised version of notificationEmailSubjectChangeHandle & notificationDescriptionChangeHandle,
    // that can be used for any channel
    const notificationTextChangeHandle = (input, inputFieldType) => {
        return validateTextField(input, inputFieldType, setInputErrors);
    };

    const validateNotificationType = (notificationType) => {
        setNotificationType(notificationType);
        if (notificationType === '') {
            setInputErrors(prevState => ({
                ...prevState,
                notificationType: {hasError: true, errorMessage: inputErrors.notificationType.errorMessage}
            }));
            return false;
        } else {
            setInputErrors(prevState => ({
                ...prevState,
                notificationType: {hasError: false, errorMessage: ''}
            }));
        }
        return true;
    };

    const validateNotificationCode = async () => {
        const pattern = /^[A-Z_]{3,}$/;
        const code = newNotificationRequest.notificationCode;
        let valid;

        if (code.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'Enter a notification code'}
            }));
            valid = false;
        } else if (code.length > 50) {
            /**
             * customer_notification repo table's have limit of 50 characters for notification_code in notification_batch table
             * Hence applying this max length check
             */
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'Max 50 characters allowed in Notification code'}
            }));
            valid = false;
        } else if (code.length < 3) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'Code must be at least 3 characters'}
            }));
            valid = false;
        } else {
            valid = pattern.test(code);
            const err = valid ? '' : 'Enter only uppercase letters and underscores';
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: !valid, errorMessage: err}
            }));
            if (valid) {
                await apiServices.searchNotificationCode(code).then(() => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        notificationCode: {hasError: true, errorMessage: 'Notification code already exists'}
                    }));
                    valid = false;
                })
                    .catch((error) => {
                        /**
                         * Search Notification returns error if notification does not exists with notificationCode
                         * So set the error to empty string and return valid = true
                         * It might also return an error if code exists, but user does not have access permission to
                         * the existing code
                         */
                        if (error.response.status === 403) {
                            setInputErrors(prevState => ({
                                ...prevState,
                                notificationCode: {hasError: true, errorMessage: 'Notification code already exists'}
                            }));
                            valid = false;
                        } else {
                            const err = '';
                            setInputErrors(prevState => ({
                                ...prevState,
                                notificationCode: {hasError: !valid, errorMessage: err}
                            }));
                            valid = true;
                        }
                    });
            }
        }
        return valid;
    };
    //Validates all email inputs before making post request.
    const validateEmailRequest = (emailHeaderTemplate, emailContent, emailFooterTemplate) => {
        let validationResult = true;
        if (!notificationTextChangeHandle(newNotificationRequest.emailSubject, 'emailSubject')) {
            validationResult = false;
        }
        if (emailFooterTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailFooterTemplate: {hasError: true, errorMessage: 'Select an email footer'}
            }));
            validationResult = false;
        }
        if (emailHeaderTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailHeaderTemplate: {hasError: true, errorMessage: 'Please provide an Email Header Template'}
            }));
            validationResult = false;
        }
        if (emailContent.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {hasError: true, errorMessage: 'Enter email body content in html format'}
            }));
            validationResult = false;
        }
        if (emailContent.length > 0 && !emailContentValidation.current) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {
                    hasError: true,
                    errorMessage: 'Check HTML content before submitting'
                }
            }));
            validationResult = false;
        }
        return validationResult;
    };
    //Validates all inbox inputs before making post request.
    const validateInboxRequest = (inboxContent, inboxFooterTemplate) => {
        let validationResult = true;
        if (!notificationTextChangeHandle(newNotificationRequest.inboxSubject, 'inboxSubject')) {
            validationResult = false;
        }
        if (inboxContent.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxContent: {hasError: true, errorMessage: 'Enter inbox body content in html format'}
            }));
            validationResult = false;
        }
        if (inboxContent.length > 0 && !inboxContentValidation.current) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxContent: {
                    hasError: true,
                    errorMessage: 'Check HTML content before submitting'
                }
            }));
            validationResult = false;
        }
        if (inboxFooterTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxFooterTemplate: {hasError: true, errorMessage: 'Select an inbox footer'}
            }));
            validationResult = false;
        }
        return validationResult;
    };
    //Validates all push inputs before making post request.
    const validatePushRequest = () => {
        let validationResult = true;
        if (!notificationTextChangeHandle(newNotificationRequest.pushSubject, 'pushSubject')) {
            validationResult = false;
        }
        if (!notificationTextChangeHandle(newNotificationRequest.pushContent, 'pushContent')) {
            validationResult = false;
        }
        return validationResult;
    };

    // Select left-most content tab where there is an error showing
    const switchToTabOnValidation = (tabErrorsStatus) => {
        const tabs = tabErrorsStatus.filter(channel => channel.enabled);
        const tabIndex = tabs.findIndex(tab => tab.hasErrors);
        if (tabIndex > -1) {
            setTabIndex(tabIndex);
        }
    };

    const validateRequest = async () => {
        let valid;
        const {
            sendSmsEnabled,
            sendEmailEnabled,
            sendPushEnabled,
            showInInbox,
            emailHeaderTemplate,
            emailContent,
            emailFooterTemplate,
            inboxContent,
            inboxFooterTemplate,
            emailFeedbackTemplate,
            emailSourceApplicationTUTD,
            notificationType
        } = newNotificationRequest;
        // For all error messages to appear each of these functions need to run. If notificationTextChangeHandle
        // evaluated to false, the other functions were not being executed, this was fixed below.
        // valid = await (notificationTextChangeHandle(newNotificationRequest.description, 'description')
        //     && validateNotificationCode() && emailSourceApplicationTUTDChangeHandle());
        const descriptionValidation = notificationTextChangeHandle(newNotificationRequest.description, 'description');
        const notificationCodeValidation = await validateNotificationCode();
        const emailSourceAppValidation = await emailSourceApplicationTUTDChangeHandle();
        valid = validateNotificationType(notificationType) && emailSourceAppValidation &&
            notificationCodeValidation && descriptionValidation;

        if (selectedServiceCode === '' || selectedServiceCode === null) {
            setInputErrors(prevState => ({
                ...prevState,
                service: {hasError: true, errorMessage: 'Please select a service'}
            }));
            valid = false;
        }
        if (sendSmsEnabled === false && sendEmailEnabled === false && sendPushEnabled === false && showInInbox === false) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationChannels: {hasError: true, errorMessage: 'You must select at least one channel'}
            }));
            valid = false;
        }
        const contentTabsErrorStatus = [
            {enabled: sendEmailEnabled, hasErrors: false},
            {enabled: sendPushEnabled, hasErrors: false},
            {enabled: showInInbox, hasErrors: false},
            {enabled: sendSmsEnabled, hasErrors: false}
        ];
        //Validates email template contents.
        if (sendEmailEnabled && !validateEmailRequest(emailHeaderTemplate, emailContent, emailFooterTemplate)) {
            valid = false;
            contentTabsErrorStatus[0].hasErrors = true;
        }
        //Validates push template contents.
        if (sendPushEnabled && !validatePushRequest()) {
            valid = false;
            contentTabsErrorStatus[1].hasErrors = true;
        }
        //Validates inbox template contents.
        if (showInInbox && !validateInboxRequest(inboxContent, inboxFooterTemplate)) {
            valid = false;
            contentTabsErrorStatus[2].hasErrors = true;
        }
        //Validates SMS template contents.
        if (sendSmsEnabled && !notificationTextChangeHandle(newNotificationRequest.smsContent, 'smsContent')) {
            valid = false;
            contentTabsErrorStatus[3].hasErrors = true;
        }
        switchToTabOnValidation(contentTabsErrorStatus);
        if (!sendEmailEnabled && (emailHeaderTemplate.length > 0 || emailFooterTemplate.length > 0
            || emailFeedbackTemplate.length > 0
            || emailSourceApplicationTUTD.trim().length > 0)) {
            setNewNotificationRequest(prevState => ({
                ...prevState,
                emailHeaderTemplate: '',
                emailFooterTemplate: '',
                emailFeedbackTemplate: '',
                emailSourceApplicationTUTD: ''
            }));
        }
        return valid;
    };

    //region - Navigation functions
    const showSuccessAlert = (alert) => {
        if (from === 'dashboard') {
            navigate('/dashboard', {
                state: {
                    alert: alert,
                    isExpanded: true,
                    serviceCode: selectedServiceCode
                }
            });
        } else {
            navigate(`/services/${selectedAgencyCode}`, {
                state: {
                    alert: alert,
                    isExpanded: true,
                    serviceCode: selectedServiceCode
                }
            });
        }
    };

    const showErrorAlert = (e) => {
        setAlert({
            variant: 'error',
            title: 'Network Error',
            description: e.message,
            visible: true
        });
    };
    const discardChanges = () => {
        if (from === 'dashboard') {
            navigate('/dashboard');
        } else {
            if (agencyCodeFromPath != null && agencyCodeFromPath !== '') {
                navigate(`/services/${agencyCodeFromPath}`, {
                    state: {
                        alert: {
                            variant: 'info',
                            title: 'Cancelled',
                            description: 'Changes cancelled',
                            visible: true
                        },
                        isExpanded: true,
                        serviceCode: serviceCode
                    }
                });
            }
        }
    };
    // endregion
    const createNotification = async () => {
        setLoading(true);
        newNotificationRequest.notificationType = notificationType;
        validateRequest().then((valid) => {
            if (valid) {
                apiServices.postNotificationConfig(newNotificationRequest)
                    .then((response) => {
                        const notificationConfig = response.data;
                        const promises = [];
                        if (newNotificationRequest.sendEmailEnabled && newNotificationRequest.emailContent.length > 0) {
                            newNotificationRequest.emailContent = encodeHTMLEntities(newNotificationRequest.emailContent);
                            promises.push(
                                apiServices.createEmailTemplateConfigs(notificationConfig.notificationCode
                                    , newNotificationRequest));
                        }
                        if (newNotificationRequest.showInInbox && newNotificationRequest.inboxContent.length > 0) {
                            newNotificationRequest.inboxContent = encodeHTMLEntities(newNotificationRequest.inboxContent);
                            promises.push(
                                apiServices.createInboxTemplateConfigs(newNotificationRequest));
                        }
                        if (newNotificationRequest.sendPushEnabled) {
                            promises.push(apiServices.createPushTemplateConfigs(newNotificationRequest));
                        }
                        if (newNotificationRequest.sendSmsEnabled) {
                            promises.push(apiServices.createSmsTemplateConfigs(newNotificationRequest));
                        }
                        Promise.allSettled(promises)
                            .then(returnValues => {
                                let hasError = false;
                                const errors = [];
                                returnValues.forEach((response) => {
                                    if (response.status === 'rejected') {
                                        hasError = true;
                                        errors.push(response.reason.response.data);
                                    }
                                });
                                if (hasError) {
                                    console.log(JSON.stringify(errors));
                                    setAlert({
                                        variant: 'error',
                                        title: 'Network Error',
                                        description: 'An error occurred creating one or all of the templates.',
                                        visible: true
                                    });
                                } else {
                                    showSuccessAlert({
                                        variant: 'success',
                                        title: 'Notification created and submitted',
                                        description: //eslint-disable-next-line max-len
                                            'Your notification has been successfully created and is now being reviewed by the notifications team. ' +
                                            'We will be in contact via email with next steps regarding your notification.',
                                        visible: true
                                    });
                                }
                            });
                    })
                    .catch(e => {
                        showErrorAlert(e);
                        setLoading(false);
                    });
            } else {
                setLoading(false);
                const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
                if (errorElement !== undefined) {
                    errorElement.scrollIntoView({
                        alignToTop: true,
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
        });
    };

    return (
        <div style={ {whiteSpace: 'break-spaces', paddingTop: '3.5rem'} }>
            {loading && <ComponentLoader fullPage label='Creating the Notification...'/>}
            <CustomContentContainer>
                <div style={ {width: '100%'} }>
                    <Button variant='back' id='backBtn' onClick={ open }>Back</Button>
                </div>
                <Heading
                    className='page-title'
                    style={ {
                        height: 'fit-content',
                        margin: '18px 0 0 0'
                    } }
                    level={ 1 }>
                    Create a new notification
                </Heading>
                <Form data-test='new-notification-form'>
                    <Row>
                        <Col span={ 9 }>
                            <FormInput
                                label={ notificationDescriptionFormField.label }
                                name={ notificationDescriptionFormField.name }
                                errorMessage={ inputErrors.description.errorMessage }
                                hasError={ inputErrors.description.hasError }
                                helpMessage={ notificationDescriptionFormField.helpMessage }
                                margin={ {top: 24} }
                                onBlur={ () => notificationTextChangeHandle(newNotificationRequest.description, 'description') }
                                onChange={ handleChange }
                                value={ newNotificationRequest.description }
                                placeholder={ notificationDescriptionFormField.placeholder }
                                data-testid={ 'notificationDescription' }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 }
                            style={ {paddingTop: '10px'} }>
                            <RadioButtonGroup
                                data-testid={ 'notification-types' }
                                label={ 'Notification Type' }
                                options={ notificationTypes }
                                onChange={ validateNotificationType }
                                selectedValue={ notificationType }
                                errorMessage={ inputErrors.notificationType.errorMessage }
                                hasError={ inputErrors.notificationType.hasError }
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 } style={ {display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '3%'} }>
                            <FormSelect
                                label={ 'Select service' }
                                name={ 'selectService' }
                                style={ {width: '206%', maxWidth: '206%'} }
                                errorMessage={ inputErrors.service.errorMessage }
                                hasError={ inputErrors.service.hasError }
                                placeholder={ serviceSelectFormField.placeholder }
                                options={ serviceOptions }
                                margin={ {top: 16} }
                                onChange={ handleServiceSelect }
                                value={ selectedServiceCode }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 }>
                            <FormInput
                                label={ notificationCodeFormField.label }
                                name={ notificationCodeFormField.name }
                                hasError={ inputErrors.notificationCode.hasError }
                                helpMessage={ notificationCodeFormField.helpMessage }
                                errorMessage={ inputErrors.notificationCode.errorMessage }
                                margin={ {top: 24} }
                                onBlur={ validateNotificationCode }
                                onChange={ handleChange }
                                value={ newNotificationRequest.notificationCode }
                                placeholder={ notificationCodeFormField.placeholder }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 }>
                            <FormCheckboxGroup
                                dataTest='notification-channels'
                                id='notification-channels'
                                name={ notificationChannelFormField.name }
                                legend={ notificationChannelFormField.legend }
                                helpMessage={
                                    <TextLink
                                        target={ '_blank' }
                                        href={ `${process.env.REACT_APP_SERVICE_NSW_WIKI_URL}/11926472274/Delivery+channels` }>
                                        How to select the right channels
                                    </TextLink>
                                }
                                errorMessage={ inputErrors.notificationChannels.errorMessage }
                                hasError={ inputErrors.notificationChannels.hasError }
                                options={ checkboxChannels }
                                onChange={ onChannelChangeEvent }
                                margin={ {top: 24} }
                                aria-describedby='checkboxHelpText'
                            />
                        </Col>
                    </Row>
                    <ContentHeadingAndGuidance/>
                </Form>
            </CustomContentContainer>
            {(newNotificationRequest.showInInbox || newNotificationRequest.sendPushEnabled ||
                    newNotificationRequest.sendSmsEnabled || newNotificationRequest.sendEmailEnabled) &&
                <TabsContainer selectedTabClassName='is-selected'
                    selectedIndex={ tabIndex } onSelect={ (index) => setTabIndex(index) }>
                    <CustomContentContainerForTabHeadings>
                        <TabHeadingsContainer>
                            {newNotificationRequest.sendEmailEnabled &&
                                <TabHeading data-testid={ 'emailTab' }><IconEmail/>Email</TabHeading>
                            }
                            {newNotificationRequest.sendPushEnabled &&
                                <TabHeading data-testid={ 'pushTab' }><IconMobile/>Push</TabHeading>
                            }
                            {newNotificationRequest.showInInbox &&
                                <TabHeading data-testid={ 'inboxTab' }><IconNotifications/>Inbox</TabHeading>
                            }
                            {newNotificationRequest.sendSmsEnabled &&
                                <TabHeading data-testid={ 'smsTab' }><IconFeedback/>SMS</TabHeading>
                            }
                        </TabHeadingsContainer>
                    </CustomContentContainerForTabHeadings>
                    <TabPanelContainer>
                        {newNotificationRequest.sendEmailEnabled &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <EmailContentFormComponent
                                        templateOptions={ templateOptions }
                                        notificationConfig={ newNotificationRequest }
                                        setNotificationConfig={ setNewNotificationRequest }
                                        handleChange={ handleChange }
                                        setShowEmailPreviewModal={ setShowEmailPreviewModal }
                                        emailContentValidated={ emailContentValidation }
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>}
                        {newNotificationRequest.sendPushEnabled &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <PushContentFormComponent
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        handleChange={ handleChange }
                                        pushSubject={ newNotificationRequest.pushSubject }
                                        pushContent={ newNotificationRequest.pushContent }
                                        setShowPushPreviewModal={ setShowPushPreviewModal }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>}
                        {newNotificationRequest.showInInbox &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <InboxContentFormComponent
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        handleChange={ handleChange }
                                        notificationConfig={ newNotificationRequest }
                                        setNotificationConfig={ setNewNotificationRequest }
                                        inboxFooterOptions={ templateOptions.inboxFooters }
                                        inboxContentValidation={ inboxContentValidation }
                                        setShowInboxPreviewModal={ setShowInboxPreviewModal }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>}
                        {newNotificationRequest.sendSmsEnabled &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <SmsContentFormComponent
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        handleChange={ handleChange }
                                        smsContent={ newNotificationRequest.smsContent }
                                        setShowSmsPreviewModal={ setShowSmsPreviewModal }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>}
                    </TabPanelContainer>
                    <FormFooterContainer style={ {paddingTop: '32px'} }>
                        <CustomContentContainer>
                            <HorizontalRule style={ {
                                margin: '0'
                            } }/>
                            {alert.visible ? <Row>
                                <Col span={ 9 }><InPageAlert id='notification-status' variant={ alert.variant }
                                    title={ alert.title }>
                                    <p data-test='notStatusDesc'>{alert.description}</p>
                                </InPageAlert> </Col></Row> : null}
                            <FormButtonContainer style={
                                {
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    justifyContent: 'start',
                                    flexDirection: 'column',
                                    rowGap: '32px',
                                    marginTop: '32px'
                                }
                            }>
                                <div style={
                                    {
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        columnGap: '25px'
                                    }
                                }>
                                    <Button
                                        onClick={ createNotification }
                                        variant='primary'
                                        id='createNotificationBtn'>
                                        Save and submit
                                    </Button>
                                </div>
                                <StatusNextInfoMsg/>
                            </FormButtonContainer>
                        </CustomContentContainer>
                    </FormFooterContainer>
                </TabsContainer>
            }
            {showModal && (
                <Modal
                    title='Are you sure you want to discard your unsaved changes?'
                    description='By going back you will loose all the progress on this form
                                 and no Notification will be created.'
                    buttons={ [
                        {text: 'Discard', onClick: discardChanges},
                        {text: 'Cancel', onClick: () => close()}
                    ] }
                />
            )}
            {showInboxPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.INBOX }
                    inboxPreviewSubstitutes={ {
                        inboxSubject: newNotificationRequest.inboxSubject,
                        inboxContent: newNotificationRequest.inboxContent,
                        inboxFooter: newNotificationRequest.inboxFooterTemplate.length > 0 ?
                            templateOptions.inboxFooters.find((footer) =>
                                footer.value === newNotificationRequest.inboxFooterTemplate).content : ''
                    } }
                    sender={ agencyName }
                    service={ serviceName }
                    notificationCode={ newNotificationRequest.notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowInboxPreviewModal }
                />
            }
            {showEmailPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.EMAIL }
                    emailPreviewSubstitutes={ {
                        emailContent: newNotificationRequest.emailContent,
                        emailFooter: templateOptions.footers.find((footer) =>
                            footer.value === newNotificationRequest.emailFooterTemplate).content,
                        emailHeader: templateOptions.headers.find((header) =>
                            header.value === newNotificationRequest.emailHeaderTemplate).content,
                        emailFeedback: newNotificationRequest.emailFeedbackTemplate.length > 0 ?
                            templateOptions.feedbacks.find((feedback) =>
                                feedback.value === newNotificationRequest.emailFeedbackTemplate).content : '',
                        emailSubject: newNotificationRequest.emailSubject
                    } }
                    sender={ agencyName }
                    service={ serviceName }
                    notificationCode={ newNotificationRequest.notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowEmailPreviewModal }
                />
            }
            {showPushPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.PUSH }
                    pushPreviewSubstitutes={ {
                        pushContent: newNotificationRequest.pushContent,
                        pushSubject: newNotificationRequest.pushSubject
                    } }
                    sender={ agencyName }
                    service={ serviceName }
                    notificationCode={ newNotificationRequest.notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowPushPreviewModal }
                />
            }
            {showSmsPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.SMS }
                    smsContent={ newNotificationRequest.smsContent }
                    sender={ agencyName }
                    service={ serviceName }
                    notificationCode={ newNotificationRequest.notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowSmsPreviewModal }
                />
            }
        </div>
    );
};

CreateNotificationComponent.propTypes = {
    onChange: PropTypes.func
};
export default CreateNotificationComponent;

import styled from 'styled-components';

/* Please go to {REACT_APP_HOME_URL}/typography to view fonts on the UI */

export const globalFont = 'Public Sans, Helvetica, Arial, sans-serif';

export const screenSize = {
    mobile: '480px',
    tablet: '768px',
    desktop: '1224px'
};

export const colors = {
    brand: {
        snswPrimaryBlue: '#002664',
        snswPrimaryRed: '#d7153a',
        snswSecondaryBlue: '#2e5299',
        snswTertiaryBlue: '#0a7cb9',
        snswDarkGrey: '#242934',
        snswInfoBlueLight: '#eaedf4'
    },
    white: '#ffffff',
    black: '#000000',
    grey: {
        darkGrey: '#242934',
        shuttle: '#646974',
        geyser: '#dee3e5',
        mystic: '#ecf1f3',
        iceBlue: '#f4f7f9',
        neutral: '#f2f2f2',
        neutral200: '#495054'
    },
    status: {
        error: '#b81237',
        success: '#00a908',
        warning: '#dc5800',
        info: '#2e5299',
        disabled: '#646974',
        focus: '#2e5299'
    }
};

export const boxShadowStyle = '0px 0px 0px 2px #EAEDF4';

export const fontSize = {
    h1: '28px',
    h2: '25px',
    h3: '23px',
    h4: '20px',
    h5: '18px',
    h6: '16px',
    body: '14px',
    bodyXS: '12px'
};

export const lineHeight = {
    h1: '36px',
    h2: '32px',
    h3: '28px',
    h4: '28px',
    h5: '24px',
    h6: '24px',
    body: '22px',
    bodyXS: '18px'
};

export const fontWeight = {
    regular: 400,
    semibold: 600,
    bold: 700
};

const defaultHeadingColour = colors.brand.snswPrimaryBlue;

export const Heading1 = styled.h1`{
    font-size: ${fontSize.h1};
    line-height: ${lineHeight.h1};
    font-weight: ${fontWeight.bold};
    margin: 0;
    color: ${defaultHeadingColour};
}`;

export const Heading2 = styled.h2`{
    font-size: ${fontSize.h2};
    line-height: ${lineHeight.h2};
    font-weight: ${fontWeight.bold};
    margin: 0;
    color: ${defaultHeadingColour};
}`;

export const Heading3 = styled.h3`{
    font-size: ${fontSize.h3};
    line-height: ${lineHeight.h3};
    font-weight: ${fontWeight.bold};
    margin: 0;
    color: ${defaultHeadingColour};
}`;

export const Heading4 = styled.h4`{
    font-size: ${fontSize.h4};
    line-height: ${lineHeight.h4};
    font-weight: ${fontWeight.bold};
    margin: 0;
    color: ${defaultHeadingColour};
}`;

export const Heading5SemiBold = styled.h5`{
    font-size: ${fontSize.h5};
    line-height: ${lineHeight.h5};
    font-weight: ${fontWeight.semibold};
    margin: 0;
}`;

export const Heading5Bold = styled.h5`{
    font-size: ${fontSize.h5};
    line-height: ${lineHeight.h5};
    font-weight: ${fontWeight.bold};
    margin: 0;
}`;

export const Heading6Regular = styled.h6`{
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    font-weight: ${fontWeight.regular};
    margin: 0;
}`;

export const Heading6SemiBold = styled.h6`{
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    font-weight: ${fontWeight.semibold};
    margin: 0;
}`;

export const Heading6Bold = styled.h6`{
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    font-weight: ${fontWeight.bold};
    margin: 0;
}`;

export const BodyRegular = styled.div`
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    margin: 0;
`;

export const BodySemiBold = styled.div`
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.semibold};
    margin: 0;
`;

export const BodyBold = styled.div`
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.bold};
    margin: 0;
`;

export const BodyXS = styled.div`
    font-size: ${fontSize.bodyXS};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    margin: 0;
`;
import React, {useEffect, useRef, useState} from 'react';
import {
    AnchorMenuItem,
    AnchorMenuBody,
    AnchorMenuContainer, AnchorMenuDiv,
    AnchorMenuProgressStack,
    AnchorMenuTitle, AnchorMenuLink, AnchorMenuGap
} from './AnchorMenuComponent.styled';
import {Heading6Bold, colors, fontWeight} from '../Typography/Typography.styled';

function useStickyAnchorMenu() {
    const stickyRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        if (!stickyRef.current) {
            return;
        }

        const observer = new IntersectionObserver(
            ([event]) => setIsSticky(event.intersectionRatio < 1),
            {threshold: [1], rootMargin: '-1px 0px 0px 0px',}
        );
        observer.observe(stickyRef.current);

        return () => observer.disconnect();
    }, []);

    return {stickyRef, isSticky};
}

const AnchorMenuComponent = (props) => {

    const menuMetaData = props.menuMetaData;
    const [activeLinkElementId, setActiveLinkElementId] = useState(menuMetaData.data[0].id);
    const {stickyRef, isSticky} = useStickyAnchorMenu();

    const scrollToTarget = (elementId, index) => {
        setActiveLinkElementId(elementId);
        const anchorElement = document.getElementById(elementId);
        if(index === 0) { // designer wants to have the first element back to very top of the page.
            const rect = anchorElement.getBoundingClientRect();
            window.scrollTo({
                top: rect.top,
                behavior: 'smooth'
            });
        } else {
            anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const anchorMenuBody = menuMetaData.data.map((menu, index) => {

        const elementId = menu.id;

        const anchorMenuLinkPaddingLeftStyle = menu.level === 0 ? '0px'
            : menu.level === 1 ? '10px'
                : menu.level === 2 ? '20px'
                    : '0px';

        const stackBgColor = (elementId === activeLinkElementId) ? colors.brand.snswPrimaryBlue : colors.grey.geyser;
        const fontColor = (elementId === activeLinkElementId) ? colors.brand.snswPrimaryBlue : colors.grey.shuttle;
        const fontBold = (elementId === activeLinkElementId) ? fontWeight.semibold : fontWeight.regular;

        return (
            <>
                <AnchorMenuItem key={ elementId }>
                    <AnchorMenuProgressStack style={ {backgroundColor: `${stackBgColor}`} }/>
                    <AnchorMenuDiv style={ {paddingLeft:`${anchorMenuLinkPaddingLeftStyle}`,
                        color: `${fontColor}`, fontWeight: `${fontBold}`} }>
                        <AnchorMenuLink onClick={ () => scrollToTarget(elementId, index) }>{ menu.title }</AnchorMenuLink></AnchorMenuDiv>
                </AnchorMenuItem>
                <AnchorMenuGap>
                    <AnchorMenuProgressStack/>
                    <div></div>
                </AnchorMenuGap>
            </>
        );
    });

    return (
        <AnchorMenuContainer ref={ stickyRef } style={ {paddingTop: `${isSticky ? '10px': '0px'}`} }>
            <AnchorMenuTitle><Heading6Bold>{menuMetaData.title}</Heading6Bold></AnchorMenuTitle>
            <AnchorMenuBody>{anchorMenuBody}</AnchorMenuBody>
        </AnchorMenuContainer>
    );
};

export default AnchorMenuComponent;
import styled from 'styled-components';
import {
    globalFont,
    fontWeight,
    Heading6Bold, Heading4, colors, Heading5Bold, fontSize, lineHeight
} from '../CommonComponents/Typography/Typography.styled';

const codeFont = 'Menlo, Monaco, "Courier New", Courier, monospace';

export const BodyContainer = styled.div`
    display: flex; 
    margin-top: 1rem;
`;

/* Content Container Section */
export const ContentSection = styled.div`
    min-width: 726px;
    //border-top: 1px solid orange;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    padding-bottom: 56px;
`;

export const Paragraph = styled.p`
    margin: 0px;
    + p {
        margin-top: 10px;
    }
`;

export const HorizontalLine = styled.div`
    height: 2px;
    background-color: #CDD3D6;
    margin: 10px 0px 10px 0px;
`;

export const H4Heading = styled(Heading4)`
    font-family: ${globalFont};
`;

export const H5BoldHeading = styled(Heading5Bold)`
    font-family: ${globalFont};
    color: ${colors.brand.snswPrimaryBlue}
`;

export const H6BoldHeading = styled(Heading6Bold)`
    font-family: ${globalFont};
    color: ${colors.brand.snswPrimaryBlue}
`;

export const BoldFont = styled.span`
    font-weight: ${fontWeight.bold};
`;

export const SemiBoldFont = styled.span`
    font-weight: ${fontWeight.semibold};
`;

export const CodeInContent = styled.span`
    color: ${colors.brand.snswPrimaryBlue};
    background-color: #FFFFFF;
    padding: 2px 4px;
    font-family: ${codeFont};
`;

export const UnorderedList = styled.ul`
    margin: 10px 0rem 0rem 0rem;  
`;

export const ListItem = styled.li`
    margin-bottom: 0rem;  
`;

export const StartingSpace = styled.span`
    padding-left: 1.1rem;  
`;

const TableGrid = styled.div`
    display: grid;
    background-color: #FFFFFF;

    > div {
        font-family: ${globalFont};
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`;

export const Table4Col = styled(TableGrid)`
    grid-template-columns: 122px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

export const Table5Col = styled(TableGrid)`
    grid-template-columns: 122px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

const TableHead = styled.div`
    // cell format
    align-content: center;
    padding-left: 16px;
    border-bottom: 2px solid ${colors.grey.geyser};
    // font format
    color: ${colors.brand.snswPrimaryBlue};
    font-weight: ${fontWeight.semibold};
    font-size: ${fontSize.body};
    line-height: 22px;
`;

export const Table4ColHead = styled(TableHead)`
    // cell format
    min-height: 41px;
`;

export const Table5ColHead = styled(TableHead)`
    // cell format
    min-height: 64px;
`;

export const TableCell = styled.div`
    // cell format
    align-content: center;
    border-bottom: 1px solid ${colors.grey.geyser};
    padding-left: 8px;
    padding-right: 8px;
    // font format
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.regular};
    line-height: 18px;
`;

export const Table4ColCellBold = styled(TableCell)`
    // cell format
    padding-left: 16px;
    // font format
    color: ${colors.brand.snswPrimaryBlue};
    font-weight: ${fontWeight.bold};
`;

export const Table5ColCellBold = styled(TableCell)`
    // cell format
    padding-left: 16px;
    // font format
    color: ${colors.brand.snswPrimaryBlue};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.body};
    line-height: 18px;
`;

export const TableCellFlex = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    justify-content: space-evenly;
    border-bottom: 1px solid ${colors.grey.geyser};
    
    > div {
        font-size: ${fontSize.body};
        font-weight: ${fontWeight.regular};
        line-height: 18px;
    }
`;

export const GreenCodeFont = styled.span`
    color: #00D80B;
`;

export const GapSection = styled.div`
    flex: 0 0 125px;
    //border-top: 1px solid red;
`;

export const AnchorMenuSection = styled.div`
    flex: 0 0 266px;
    //border-top: 1px solid green;
`;
import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {portalConfigStatus} from '../../constants/applicationConstants';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {
    ArchivedStatusLabel,
    Cell,
    columnWidths,
    InReviewStatusLabel,
    LiveStatusLabel,
    SentStatusLabel,
    TextCell,
    TR,
    UnknownStatusLabel
} from './DashboardComponent.styled';
import {Link, useNavigate} from 'react-router-dom';
import {Button} from '@snsw-gel/react';
import {IconChevronRight} from '@snsw/react-component-library/build/Icons/system';

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip { ...props } classes={ {popper: className} }/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
}));

const DashboardNotificationListItem = props => {
    const {userRole} = useUserRoleState();
    const navigate = useNavigate();

    const editNotification = () => {
        if (userRole.permissions.NOTIFICATION.includes('UPDATE')) {
            navigate(`/update-notification/${props.notification.notificationCode}?from=dashboard`);
        }
    };
    return (
        <TR key={ props.key } role={ 'notificationListItem' } onClick={ editNotification }>
            <TextCell key={ props.index + '.1' }
                style={ {
                    width: columnWidths.notification
                } }>
                <LightTooltip key={ props.index + '.1.tooltip' } title={ props.notification.description }
                    data-testid={ 'descriptionTooltip' }>
                    {props.notification.description}
                </LightTooltip>
            </TextCell>
            <TextCell key={ props.index + '.5' }
                style={ {
                    width: columnWidths.code,
                    padding: 'unset'
                } }>
                <LightTooltip key={ props.index + '.5.tooltip' } title={ props.notification.notificationCode }
                    data-testid={ 'codeTooltip' }>
                    {props.notification.notificationCode}
                </LightTooltip>
            </TextCell>
            <TextCell key={ props.index + '.2' }
                style={ {width: columnWidths.service} }>
                <LightTooltip key={ props.index + '.2.tooltip' } title={ props.notification.serviceDescription }
                    data-testid={ 'serviceTooltip' }>
                    {props.notification.serviceDescription}
                </LightTooltip>
            </TextCell>
            <TextCell key={ props.index + '.3' }
                style={ {width: columnWidths.agency} }>
                <LightTooltip key={ props.index + '.3.tooltip' } title={ props.notification.agencyDescription }
                    data-testid={ 'agencyTooltip' }>
                    {props.notification.agencyDescription}
                </LightTooltip>
            </TextCell>
            <TextCell key={ props.index + '.4' }
                style={ {
                    width: columnWidths.channels,
                    minWidth: columnWidths.channels,
                    maxWidth: columnWidths.channels,
                    padding: 'unset',
                    position: 'sticky',
                    right: '222px',
                    zIndex: '1'
                } }>
                <LightTooltip key={ props.index + '.4.tooltip' } title={ props.notification.channels }
                    data-testid={ 'channelsTooltip' }>
                    {props.notification.channels}
                </LightTooltip>
            </TextCell>
            <TextCell key={ props.index + '.6' }
                style={ {
                    maxWidth: columnWidths.updated,
                    minWidth: columnWidths.updated,
                    width: columnWidths.updated,
                    padding: 'unset',
                    position: 'sticky', right: '122px', zIndex: '1'
                } }>
                <LightTooltip key={ props.index + '.6.tooltip' } title={ props.notification.updatedDate }
                    data-testid={ 'dateTooltip' }>
                    {props.notification.updatedDate}
                </LightTooltip>
            </TextCell>
            <Cell key={ props.index + '.7' }>
                <div style={ {
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center'
                } }>
                    <div
                        style={ {
                            maxWidth: columnWidths.status,
                            minWidth: columnWidths.status,
                            width: columnWidths.status, padding: 'unset',
                            position: 'sticky', right: '95px', zIndex: '1'
                        } }>
                        {!props.notification.status ? '' :
                            props.notification.status === portalConfigStatus.IN_REVIEW ? (
                                <LightTooltip key={ props.index + '.7.tooltip' }
                                    title={ `Notification is currently being reviewed by the notifications team in 
                            ${process.env.REACT_APP_ENV_SRC} environment.` }>
                                    <div><InReviewStatusLabel variant='warning'>In review</InReviewStatusLabel></div>
                                </LightTooltip>) :
                                props.notification.status === portalConfigStatus.SENT ? (
                                    <LightTooltip key={ props.index + '.7.tooltip' }
                                        title='Campaign has been sent'>
                                        <div><SentStatusLabel variant='info'>Sent</SentStatusLabel></div>
                                    </LightTooltip>
                                ) :
                                    props.notification.status === portalConfigStatus.ARCHIVED ? (
                                        <LightTooltip key={ props.index + '.7.tooltip' }
                                            title='Notification has been archived'>
                                            <div><ArchivedStatusLabel variant='info'>Archived</ArchivedStatusLabel>
                                            </div>
                                        </LightTooltip>
                                    ) :
                                        props.notification.status === portalConfigStatus.LIVE ? (
                                            <LightTooltip key={ props.index + '.7.tooltip' }
                                                title='Notification is in production and ready to send'>
                                                <div><LiveStatusLabel variant='success'>Live</LiveStatusLabel></div>
                                            </LightTooltip>
                                        ) : (
                                            <LightTooltip key={ props.index + '.7.tooltip' }
                                                title='Notification status is not valid'>
                                                <div><UnknownStatusLabel
                                                    variant='success'>${props.notification.status}</UnknownStatusLabel>
                                                </div>
                                            </LightTooltip>
                                        )
                        }</div>
                    <div style={ {
                        width: columnWidths.actionButton,
                        maxWidth: columnWidths.actionButton,
                        minWidth: columnWidths.actionButton,
                        position: 'sticky', right: '15px', zIndex: '1', padding: '0 6px'
                    } }>
                        {userRole.permissions.NOTIFICATION.includes('UPDATE') &&
                            <Button key={ props.index + '.8.btn' } variant='link'
                                id={ `editNotificationSettingsBtn-${props.notification.notificationCode}` }
                                data-testid={ `editNotificationSettingsBtn-${props.notification.notificationCode}` }
                                as={ Link }
                                href={ `/update-notification/${props.notification.notificationCode}?from=dashboard` }
                                style={ {pointerEvents: 'auto'} }>
                                <title>Edit notification</title>
                                <IconChevronRight key={ props.index + '.8.icon' }/>
                            </Button>
                        }        </div>
                </div>
            </Cell>
        </TR>
    );
};

DashboardNotificationListItem.propTypes = {
    index: PropTypes.number,
    serviceCode: PropTypes.string,
    notification: PropTypes.object,
    agency: PropTypes.object,
    key: PropTypes.string,
    setNotificationAlert: PropTypes.func,
    refreshNotifications: PropTypes.func
};

export default DashboardNotificationListItem;
import Form from '@snsw/react-component-library/build/Components/Form/Form';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import {colors} from '../CommonComponents/Typography/Typography.styled';
import React from 'react';

const ConfirmNotificationDetailsComponent = ({notificationDescription, notificationCode, service}) => {
    return <Form style={ {background: `${colors.grey.iceBlue}`, padding: '16px', borderRadius: '5px'} }>
        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
            <Col span={ 2 }>
                <b>Name:</b>
            </Col>
            <Col span={ 10 }>
                {notificationDescription}
            </Col>
        </Row>
        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
            <Col span={ 2 }>
                <b>Code:</b>
            </Col>
            <Col span={ 10 }>
                {notificationCode}
            </Col>
        </Row>
        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
            <Col span={ 2 }>
                <b>Channels:</b>
            </Col>
            <Col span={ 10 }>
                {service?.sendEmailEnabled ? 'Email' : ''},&nbsp;
                {service?.sendPushEnabled ? 'Push' : ''},&nbsp;
                {service?.showInInbox ? 'Inbox' : ''},&nbsp;
                {service?.sendSmsEnabled ? 'SMS' : ''}
            </Col>
        </Row>
        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
            <Col span={ 2 }>
                <b>Service:</b>
            </Col>
            <Col span={ 10 }>
                {service?.description}
            </Col>
        </Row>
    </Form>;
};

export default ConfirmNotificationDetailsComponent;
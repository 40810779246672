import styled from 'styled-components';
import {ContentContainer} from '@snsw/react-component-library';
import {ComponentLayout} from './PageSharedCss.styled';

export const ComponentWrapper = styled.div`
  > div[class*="PageWrapper"] {
  @media screen and (min-width: 48em) {
        padding: 0px 0px;
    } 
  }
`;
export const CustomerContentContainer = styled(ContentContainer)`
  > div[class*="Container"] {
    background: #fff;
    margin-top: 15px
    @media screen and (min-width: 48em) {
        max-width: 100%;
        padding-left: calc((100% - 76.5rem) / 2);
        padding-right:calc((100% - 76.5rem) / 2)
    }
  }
`;

export const BodyContainer = styled.div`
    > div:nth-child(odd) {
        background-color: #FFFFFF;
    }
`;

export const BodyWrapper = styled(ComponentLayout)`
    display: flex;
    flex-direction: column;
`;

